import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useRef, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import InputSalaryOfferModel from "../../../model/salary-offering/input-salary-offer.model";
import ResultSalaryOfferModel from "../../../model/salary-offering/result-salary-offer.model";
import EXAMPLE_JSON from "../../../assets/json/example/salary-offer.json";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import LoadingComponent from "../loading/loading";
import CreditModel from "../../../model/credit.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
const SalaryOfferComponent = ({ profileModel, authenError }: any) => {
    const { creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobExperience, setJobExperience] = useState("");
    const [jobSkill, setJobSkill] = useState("");
    const [language, setLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(EXAMPLE_JSON["result"]);
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isShare, setIsShare] = useState(false);
    const [isExample, setIsExample] = useState(true);
    const textFieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRef.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRef && textFieldRef.current) {
                    textFieldRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRef]);
    const resetALL = () => {
        setJobSkill("");
        setJobTitle("");
        setJobExperience("");
        setLanguage("");
        setResult("");
        setIsValidate(false);

    }
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {
            if(res){
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }
           
        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobExperience &&  Number(jobExperience) >= 0) {
            setIsLoading(true);
            setResult("")
            setIsValidate(true);
            let strArr: string[] = [];
            if (jobSkill) {
                const splitStr = jobSkill.split(",")
                splitStr.map((item: string, index: number) => {
                    strArr.push(item);
                })
            }
            const objectResult: InputSalaryOfferModel = {
                job_title: jobTitle,
                years: Number(jobExperience),
                skills: strArr,
                uuid: profileMod!.uuid
            }
            GPTService.postCustom(objectResult, "/salary/offer").then((res) => {
                if (res) {
                    if (res.data == "500") {
                        setResult("500")
                    }else if(res.status == 401){
                        authenError();
                    }else{
                        const answerModel: ResultSalaryOfferModel = res.data;
                        ConvertResultServices().SalaryOffering(answerModel, objectResult).then((result) => {
                            if (result) {
                                UtilityService().clickSendEvent("checking_salary_offering_" + objectResult.job_title, "click")
                                setIsLoading(false);
                                setIsShare(true);
                                setResultRawString(result);
                                setResult(result);
                                setIsExample(false)
                                GPTService.getCredit().then((res3) => {
    
                                    if(res3){
                                        setCreditModel(res3.data)
                                    }
                                })
                            }
                            setIsSubmiting(false);
                        });
                    }
                }
            })

        }
    }

    const submitWithOutCredit = () => {

    }
    
    return (

        <>
            <div className="feature-control">


                <div className="feature-form-control">
                    <div className="feature-form-left-control">
                        <h1 className="feature-main-title">{languageConfig.content.salary_offer.title}</h1>
                        <span className="feature-main-desc">{languageConfig.content.salary_offer.description}</span>
                        <div className="feature-credit-usage-control">
                            <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                            <span className="feature-credit-usage-text">ใช้เครดิต:
                                <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>
                        </div>
                        {
                            isValidate ?

                                <>
                                    <div className="dashboard-form-item-submit-control">
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Title (ตำแหน่งงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobTitle}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobExperience}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Skills (ใส่เครื่องหมาย "," หากต้องการใส่มากกว่า 1 ทักษะ)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobSkill}
                                            </div>
                                        </div>

                                        <div className="dashboard-form-item-submit-item">
                                            <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                        </div>
                                    </div>

                                </> :
                                <div className="dashboard-form-item-control">
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Title (ตำแหน่งงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Group>

                                            <Typeahead
                                                onChange={(e) => handleJobRoleChange(e)}
                                                id="valid-styles-example"
                                                inputProps={{
                                                    className: 'dashboard-input',
                                                }}
                                                options={autocompleteList}
                                                allowNew
                                            />
                                        </Form.Group>
                                        {
                                            (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job title
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control 
                                        ref={textFieldRef}
                                         
                                        type="number" className="dashboard-input" onChange={(e) => onTextChange(e, setJobExperience)}></Form.Control>
                                        {
                                            (!jobExperience && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job Experience
                                            </Form.Text>
                                        }
                                          {
                                            (Number(jobExperience) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                ไม่สามารถกรอกค่าน้อยกว่า 0
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Skills (ใส่เครื่องหมาย "," หากต้องการใส่มากกว่า 1 ทักษะ) </Form.Label>
                                        <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setJobSkill)}></Form.Control>

                                    </div>
                                    {
                                        profileMod &&
                                        <div className="d-flex">
                                            {
                                                creditModel && creditModel.credit > 0 ?
                                                    <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.salary_offer.button}</Button>
                                                    : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.salary_offer.button}</Button>
                                            }


                                        </div>
                                    }

                                </div>
                        }
                    </div>
                    <div className="">{
                           isLoading && !result ? <>
                           <LoadingComponent feature={TRICK_JSON[5].loading} listTrick={TRICK_JSON[5]} ></LoadingComponent> :
                    </> :
                        result && <ResultClinicComponent isExample={isExample} type={languageConfig.content.salary_offer.title} resultStr={result} isClose={false} isShare={isShare} isShareImg={false} setShowShare={setIsShare} ></ResultClinicComponent>
                    }

                    </div>
                </div>
            </div>
        </>
    )
}

export default SalaryOfferComponent;