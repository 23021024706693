import apiConfig from "../api-config"
import config from "../config"
export default function ApiServices() {

    return {
        async oauthToken(code, codeVerifier, redirect_uri) {
           
            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type' : config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': redirect_uri
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async refreshToken(refresh_token) {
           
            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type' : 'refresh_token',
                    'refresh_token' : refresh_token
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        
        
    }
}