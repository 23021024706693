

const storage = {
    code_verifier: "code_verifier",
    access_token: "access_token",
    refresh_token: "refresh_token",
    code: "code",
    user: "user",
    profile: "profile",
    isguest: "isguest",
    company_uuid: "company_uuid"
};

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...storage
};