
import { useContext, useEffect, useState } from "react";
import HeaderComponent from "../header/header";
import "./dashboard.scss";
import { ProviderContext } from "../../context/provider.context";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileSCModel from "../../model/profile-sc.model";
import AI_BOT_IMG from "../../assets/images/ai-bot.png";
import AI_BOT_FULL_IMG from "../../assets/images/ai-bot-full.png";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome, faIdCard, faChevronUp,
    faChevronDown, faUsers, faBullhorn,
    faIdBadge, faMoneyCheckDollar,
    faFileLines, faClipboardCheck,
    faHashtag, faClipboardQuestion,
    faLayerGroup, faClockRotateLeft, faBrain, faRobot, faGlobe
} from '@fortawesome/free-solid-svg-icons'
import languageConfig from "../../language-config";
import JDGeneratorComponent from "../features/jd-generator/jd-generator";
import JDCheckerComponent from "../features/jd-checker/jd-checker";
import ResumePrescreenComponent from "../features/resume-prescreen/resume-prescreen";
import SalaryOfferComponent from "../features/salary-offer/salary-offer";
import PostJobSocialComponent from "../features/post-job-social/post-job-social";
import InterviewQuestionComponent from "../features/interview-question/interview-question";
import TalentSearchComponent from "../talent-search/search/talent-search";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Collapse } from "@mui/material";
import GroupMenuModel, { GroupMenuItemModel } from "../../model/group-menu.model";
import CreateCareerSiteComponent from "../features/others/create-careersite/create-careersite";
import config from "../../config";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'
import CRMComponent from "../features/crm/crm";
import HistoryCreditComponent from "../history-credit/history-credit";
import apiConfig from "../../api-config";
import AccountComponent from "../account/account";
import TopupModal from "../../modal/top-up.modal";
import HistoryComponent from "../history-credit/history";
import CardRecommendModal from "../../modal/card-recommend.modal";
import CandidateModal from "../../modal/candidate.modal";
import UtilityService from "../../utils/utilities";
import menuConfig from "../../menu.config";
import AuthenMysa from "../../services/authen-mysa";
const MENU_ITEM: GroupMenuModel[] = [
    {
        group_id: 11,
        group_image: faFileLines,
        isOpen: true,
        group: languageConfig.main_menu.group_find_talent,
        isShow: menuConfig.menu.talent_acquisition.main,
        list_menu: [
            {
                id: 115, image: faFileLines, name: languageConfig.main_menu.talent_search,
                isPro: false,
                isAI: false,
                path: apiConfig.menu.talent_search,
                is_show: menuConfig.menu.talent_acquisition.talent_search
            },
            {
                id: 111, image: faFileLines, name: languageConfig.main_menu.jd_generator,
                isPro: false,
                isAI: true,
                path: apiConfig.menu.jd_generator,
                is_show: menuConfig.menu.talent_acquisition.jd_generator
            }, {
                id: 112, image: faClipboardCheck, name: languageConfig.main_menu.jd_checker,
                isPro: false,
                isAI: true,
                path: apiConfig.menu.jd_checker,
                is_show: menuConfig.menu.talent_acquisition.jd_checker
            }, {
                id: 113, image: faHashtag, name: languageConfig.main_menu.jobpost_generator,
                isPro: false,
                isAI: true,
                path: apiConfig.menu.post_job_social,
                is_show: menuConfig.menu.talent_acquisition.job_post
            }, {
                id: 114, image: faHashtag, name: languageConfig.main_menu.post_job_ats,
                isPro: true,
                isAI: false,
                path: apiConfig.menu.ats,
                is_show: menuConfig.menu.talent_acquisition.post_hr9
            }

        ]
    },
    {
        group_id: 22,
        group_image: faUsers,
        isOpen: true,
        isShow: menuConfig.menu.manage_candidates.main,
        group: languageConfig.main_menu.group_manage_talent,
        list_menu: [
            {
                id: 221, image: faIdCard, name: languageConfig.main_menu.resume_screen,
                isPro: false,
                isAI: true,
                path: apiConfig.menu.resume_prescreen,
                is_show: menuConfig.menu.manage_candidates.resume_prescreen
            },
            {
                id: 222, image: faClipboardQuestion, name: languageConfig.main_menu.interview_question,
                isPro: false,
                isAI: true,
                path: apiConfig.menu.interview_questions_generator,
                is_show: menuConfig.menu.manage_candidates.interview_question
            },
            {
                id: 223, image: faMoneyCheckDollar, name: languageConfig.main_menu.salary_offering,
                isPro: false,
                isAI: false,
                path: apiConfig.menu.salary_offering,
                is_show: menuConfig.menu.manage_candidates.salary_offering
            },
            {
                id: 224, image: faUsers, name: languageConfig.main_menu.manage_candidate_ats,
                isPro: true,
                isAI: false,
                path: apiConfig.menu.ats,
                is_show: menuConfig.menu.manage_candidates.ats_hr9
            }
        ]
    },
    {
        group_id: 33,
        group_image: faLayerGroup,
        isOpen: false,
        group: languageConfig.main_menu.group_others,
        isShow: menuConfig.menu.company_branding.main,
        list_menu: [
            {
                id: 332, image: faGlobe, name: languageConfig.main_menu.create_career_site,
                isPro: true,
                isAI: false,
                path: apiConfig.menu.ats,
                is_show: menuConfig.menu.company_branding.career_site
            },
            {
                id: 331, image: faBullhorn, name: languageConfig.main_menu.line_oa_crm,
                isPro: true,
                isAI: false,
                path: apiConfig.menu.crm,
                is_show: menuConfig.menu.company_branding.crm
            }]
    }
]
const DashboardComponent = () => {
    const [openTopup, setOpenTopup] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const { utmSource, utmMedium, version, feature } = getUTMParams(location);
    const { creditModel, setCreditModel, profileModel }: any = useContext(ProviderContext)
    const [profileMod, setProfileMod] = useState<ProfileSCModel>();
    const [menuItem, setMenuItem] = useState<GroupMenuModel[]>(MENU_ITEM);
    const [currentMenu, setCurrentMenu] = useState(88);
    const [isHideDrawer, setIsHideDrawer] = useState(true);
    const onlyWidth = useWindowWidth();
    const [isShowRecommend, setIsShowRecommend] = useState(false);
    const [isOpenCandidateModal, setOpenCandidateModal] = useState(false);
    const [candidateRecommendID, setCandidateRecommendID] = useState("");
    const [isHistoryCandidate, setIsHistoryCandidate] = useState(false);
    const [isShowAI, setIsShowAI] = useState(true);

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
            if(location.pathname.includes("talent-search")){
                setCurrentMenu(115)
            }else if(location.pathname.includes("jd-generator")){
                setCurrentMenu(111)
            }else if(location.pathname.includes("jd-checker")){
                setCurrentMenu(112)
            }else if(location.pathname.includes("post-job-social")){
                setCurrentMenu(113)
            }else if(location.pathname.includes("resume-prescreen")){
                setCurrentMenu(221)
            }else if(location.pathname.includes("interview-questions-generator")){
                setCurrentMenu(222)
            }else if(location.pathname.includes("salary-offer")){
                setCurrentMenu(223)
            }

        } else {
            console.log(location);
            let redirectPath = "/dashboard"
            if(location.pathname.includes("talent-search")){
               redirectPath = "/dashboard/talent-search";
            }
            
            handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, version,redirectPath);
        }
    }, [profileModel])

    useEffect(() => {
        if (onlyWidth) {
            if (onlyWidth > 772) {
                setDrawerWidth(280)
                setIsHideDrawer(false)
            }
        }
    }, [onlyWidth])
    useEffect(()=>{
        if(!profileMod){
            getProfile();
        }else{
            console.log(location);
            let redirectPath = "/dashboard"
            if(location.pathname.includes("talent-search")){
               redirectPath = "/dashboard/talent-search";
            }
        }
      
    },[])

    const authenError = () => {
        console.log(location);
        let redirectPath = location.pathname;
       
        handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, version,redirectPath);
    }
    const submitWithOutCredit = () => {
        setOpenTopup(true);
    }
    const getProfile = async () => {
        const profileResult = await AuthenMysa().getProfile();
        if (profileResult) {
            setProfileMod(profileResult["data"]);
            const profileModels = profileResult["data"];

        }
    }
    const handleCloseRecommend = () => {
        setIsShowRecommend(false);
    }

    const handleCloseCandidateModal = () => {
        setOpenCandidateModal(false);
    }

    const clickRecommendCandidate = (id, history) => {
        setCandidateRecommendID(id)
        setOpenCandidateModal(true);
        setIsHistoryCandidate(history);
    }

    const clickHideDrawer = () => {

        if (isHideDrawer) {

            setIsHideDrawer(false);
            setDrawerWidth(280)
        } else {
            console.log(onlyWidth);
            if (onlyWidth <= 772) {
                setDrawerWidth(0)
            }

            setIsHideDrawer(true)
        }

    }

    const clickMenu = (index, item) => {
        console.log(item);
        UtilityService().clickSendEvent("click_menu_" + item, "click");
        setCurrentMenu(index);
        if (index == 11) {

        } else if (index == 332) {
            const url = config.app.hr9_landing;
            window.open(url)
        } else if (index == 224 || index == 114) {
            const url = config.app.hr9_landing;
            window.open(url)
        } else if (index == 41) {
            handleNavigation(navigate, "/dashboard" + apiConfig.menu.history_credit, utmSource, utmMedium, feature)
        } else if (index == 88) {
            clickHideDrawer();
            handleNavigation(navigate, "/dashboard" + apiConfig.menu.profile, utmSource, utmMedium, feature)
        }
        else {
            MENU_ITEM.map((item: GroupMenuModel, i: number) => {

                item.list_menu.map((items: GroupMenuItemModel, indexs: number) => {

                    if (index == items.id) {
                        handleNavigation(navigate, "/dashboard" + items.path, utmSource, utmMedium, feature)
                    }
                })
            })
        }
        if (onlyWidth <= 772) {
            setDrawerWidth(0)
        }


        setIsHideDrawer(true)
    }

    const clickGroupMenu = (id) => {
        const groupMenuMod = [...menuItem];
        console.log(groupMenuMod);
        groupMenuMod.map((item: GroupMenuModel, index: number) => {

            if (item.group_id == id) {
                if (item.isOpen) {
                    item.isOpen = false;
                } else {
                    item.isOpen = true
                }

            }
        })
        setMenuItem(groupMenuMod);

    }
    return (

        <div className="dashboard-control">
            <div className="dashboard-menu-control">
                <CssBaseline />
                <HeaderComponent clickHideDrawer={clickHideDrawer} profileModel={profileMod} clickMenu={clickMenu}></HeaderComponent>

                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            {
                                menuConfig.menu.home &&
                                <ListGroup className="dashboard-list-item-group" >
                                    <ListGroupItem aria-expanded={false} >
                                        <ListItemButton className={currentMenu == 99 ? "dashboard-list-group-active" : "dashboard-list-group"}
                                            onClick={() => clickMenu(99, 'home')}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faHome}
                                                    className={currentMenu == 99 ? "dashboard-list-icon-active" : "dashboard-list-icon"}
                                                ></FontAwesomeIcon>
                                            </ListItemIcon>
                                            <ListItemText className="dashboard-list-group-text">{languageConfig.main_menu.home} </ListItemText>
                                        </ListItemButton>
                                    </ListGroupItem>
                                </ListGroup>
                            }
                            {
                                menuConfig.menu.profile &&
                                <ListGroup className="dashboard-list-item-group" >
                                    <ListGroupItem aria-expanded={false} >
                                        <ListItemButton className={currentMenu == 88 ? "dashboard-list-group-active" : "dashboard-list-group"}
                                            onClick={() => clickMenu(88, 'profile')}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faIdBadge}
                                                    className={currentMenu == 88 ? "dashboard-list-icon-active" : "dashboard-list-icon"}
                                                ></FontAwesomeIcon>
                                            </ListItemIcon>
                                            <ListItemText className="dashboard-list-group-text">{languageConfig.main_menu.profile} </ListItemText>
                                        </ListItemButton>
                                    </ListGroupItem>
                                </ListGroup>
                            }
                    
                            { 
                                
                                menuItem.map((item: GroupMenuModel, index: number) => {
                                    return (
                                        <ListGroup key={index} className={
                                            item.group_id == 11 && menuConfig.menu.talent_acquisition.main ? "dashboard-list-item-group":
                                            item.group_id == 11 && menuConfig.menu.talent_acquisition.main ? "d-none":
                                            item.group_id == 33 && menuConfig.menu.company_branding.main ? "dashboard-list-item-group":
                                            item.group_id == 33 && menuConfig.menu.company_branding.main ? "d-none":
                                            item.group_id == 22 && menuConfig.menu.manage_candidates.main ? "dashboard-list-item-group":
                                            "d-none"
                                        } >
                                            <ListGroupItem aria-expanded={false} >
                                                <ListItemButton className={currentMenu == 444 ? "dashboard-list-group-active" : "dashboard-list-group"}
                                                    onClick={() => clickGroupMenu(item.group_id)}>
                                                    <ListItemIcon>
                                                        <FontAwesomeIcon icon={item.group_image}
                                                            className="dashboard-list-icon"
                                                        ></FontAwesomeIcon>
                                                    </ListItemIcon>
                                                    <ListItemText className="dashboard-list-group-text">{item.group} </ListItemText>
                                                    <ListItemIcon>
                                                        <FontAwesomeIcon icon={item.isOpen ? faChevronUp : faChevronDown}
                                                            className="dashboard-list-icon-arrow"
                                                        ></FontAwesomeIcon>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                                <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            item.list_menu.map((items: GroupMenuItemModel, indexs: number) => {

                                                                return (
                                                                    items.is_show &&
                                                                    <ListItem key={indexs} disablePadding >
                                                                        <ListItemButton
                                                                            onClick={() => clickMenu(items.id, items.name)}
                                                                            className={currentMenu == items.id ? "dashboard-list-item-active" : "dashboard-list-item"} >
                                                                            {/* <ListItemIcon>
                                                                                <FontAwesomeIcon icon={items.image} className="dashboard-list-icon" ></FontAwesomeIcon>
                                                                            </ListItemIcon> */}
                                                                            <ListItemText className="dashboard-list-text">
                                                                                <div className="d-flex">
                                                                                    <div className="d-flex" dangerouslySetInnerHTML={{ __html: items.name }}></div>
                                                                                    {
                                                                                        items.isAI && <div className="dashboard-ai-text">
                                                                                            <img src={AI_BOT_IMG} className="dashboard-ai-img"></img></div>
                                                                                    }
                                                                                </div></ListItemText>
                                                                            {
                                                                                items.isPro &&
                                                                                <div className="dashboard-premium-badge-control">{languageConfig.main_menu.is_pro}</div>
                                                                            }
                                                                        </ListItemButton>
                                                                    </ListItem>

                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </ListGroupItem>
                                        </ListGroup>
                                    )
                                })
                            }
                        </List>

                    </Box>
                </Drawer>
                <Box component="main" className="dashboard-box-main-control" sx={{ flexGrow: 1, p: 3 }} >
                    <Toolbar />
                    {
                        currentMenu == 0 && null
                    }
                    {
                        currentMenu == 1 && null
                    }
                    {
                        currentMenu == 111 && <JDGeneratorComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></JDGeneratorComponent>
                    }
                    {
                        currentMenu == 112 && <JDCheckerComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></JDCheckerComponent>
                    }
                    {
                        currentMenu == 113 && <PostJobSocialComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></PostJobSocialComponent>
                    }
                    {
                        currentMenu == 114 && null
                    }
                    {
                        currentMenu == 115 && <TalentSearchComponent profileModel={profileMod} authenError={authenError}></TalentSearchComponent>
                    }
                    {
                        currentMenu == 221 && <ResumePrescreenComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></ResumePrescreenComponent>
                    }
                    {
                        currentMenu == 223 && <SalaryOfferComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></SalaryOfferComponent>
                    }
                    {
                        currentMenu == 222 && <InterviewQuestionComponent profileModel={profileMod} submitWithOutCredit={submitWithOutCredit} authenError={authenError}></InterviewQuestionComponent>
                    }
                    {
                        currentMenu == 224 && null
                    }
                    {
                        currentMenu == 331 && <CRMComponent profileModel={profileMod}></CRMComponent>
                    }
                    {
                        currentMenu == 41 && <HistoryComponent profileModel={profileMod}></HistoryComponent>
                    }
                    {
                        currentMenu == 99 && <HistoryComponent profileModel={profileMod}></HistoryComponent>
                    }
                    {
                        currentMenu == 88 && <AccountComponent ></AccountComponent>
                    }
                </Box>
            </div>

            <TopupModal open={openTopup} handleClose={setOpenTopup}></TopupModal>
            {
                //     isShowAI && <div className="dashboard-ai-show-control">
                //     <div className="dashboard-ai-quote" onClick={() => setIsShowRecommend(true)}>
                //         <span className="dashboard-ai-quote-text">เรามี Resumes มาแนะนำ</span></div>
                //     <img src={AI_BOT_FULL_IMG} className="dashboard-ai-show-img" onClick={() => setIsShowAI(false)}></img>
                // </div>
            }

            {
                isShowRecommend &&
                <CardRecommendModal open={isShowRecommend} handleClose={handleCloseRecommend} clickRecommendCandidate={clickRecommendCandidate}></CardRecommendModal>
            }
            {
                isOpenCandidateModal &&
                <CandidateModal handleClose={handleCloseCandidateModal} isHistory={isHistoryCandidate} open={isOpenCandidateModal} id={candidateRecommendID} profileModel={profileMod}></CandidateModal>
            }
        </div>
    )
}

export default DashboardComponent;