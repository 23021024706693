import { Modal } from "react-bootstrap"
import "./card-recommend.modal.scss";
import PROFILE_AI_IMG from "../assets/images/ai-bot.png";
import { useEffect, useState } from "react";
import { CandidateRecommendModel, ResumeCandidateRecommend } from "../model/talent-search/candidate-recommend.model";
import languageConfig from "../language-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCoins } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import AI_BOT_LOADING from "../assets/images/ai-bot-loading.png";
import TalentSearchService from "../services/talentsearch.service";

const CardRecommendModal = ({ open, handleClose, clickRecommendCandidate }: any) => {

    const [candidateList, setCandidateList] = useState<CandidateRecommendModel>();
    useEffect(() => {
        getCardRecommend();
    }, [])

    const getCardRecommend = () => {

        TalentSearchService.getRecommendation().then((res) => {
            if (res) {
                const result = res.data as CandidateRecommendModel;
                if (result.resumes && result.resumes.length > 0) {
                    let candidateListMod: ResumeCandidateRecommend[] = new Array();
                    result.resumes.map((item: ResumeCandidateRecommend, index: number) => {
                        item.isShow = false;
                        item.isConfirmPay = false;
                        candidateListMod.push(item);
                        if (item.used_credit) {
                            item.isShow = true;
                            item.isAnimationEnd = true;
                        } else {
                            item.isAnimationEnd = false;
                            item.isShow = false;
                        }
                    })
                    result.resumes = candidateListMod;
                    setCandidateList(result);
                }

            }
        })
    }

    const clickStatusCard = (index) => {

        if (candidateList && candidateList?.resumes) {
            const candidateListReMod = { ...candidateList };
            const candidateListMod = [...candidateList.resumes];
            candidateListMod[index].isShow = true;
            candidateListReMod.resumes = candidateListMod;
            setCandidateList(candidateListReMod)
        }

    }

    const onAnimationEnd = (element, index) => {
        if (candidateList && candidateList?.resumes) {
            const candidateListReMod = { ...candidateList };
            const candidateListMod = [...candidateList.resumes];
            candidateListMod[index].isAnimationEnd = true;
            candidateListReMod.resumes = candidateListMod;
            setCandidateList(candidateListReMod)
        }

        // element.addEventListener('animationend', (e) => {
        //     if (e.animationName === index) {
        //         console.log('exampleAnimation ended!' + index);
        //     }
        // });
    }

    const clickConfirmPay = (index) => {
        if (candidateList && candidateList?.resumes) {
            const candidateListReMod = { ...candidateList };
            const candidateListMod = [...candidateList.resumes];
            candidateListMod[index].isConfirmPay = true;
            candidateListReMod.resumes = candidateListMod;
            setCandidateList(candidateListReMod)
        }
    }

    const clickCancle = (index) => {
        if (candidateList && candidateList?.resumes) {
            const candidateListReMod = { ...candidateList };
            const candidateListMod = [...candidateList.resumes];
            candidateListMod[index].isConfirmPay = false;
            candidateListReMod.resumes = candidateListMod;
            setCandidateList(candidateListReMod)
        }
    }

    return (

        <div className="card-recommend-modal-main">
            <Modal show={open} onHide={handleClose} className="card-recommend-modal-main" >

                <Modal.Body className="card-recommend-main-control">
                    <span className="card-recommend-main-title">{languageConfig.recommend_modal.title}</span>
                    <FontAwesomeIcon icon={faClose} onClick={handleClose} className="card-recommed-close-button"></FontAwesomeIcon>
                    {
                        (candidateList && candidateList.resumes.length > 0) ?
                            <div className="card-recommend-item-control">
                                {
                                    candidateList.resumes.map((item: ResumeCandidateRecommend, index: number) => {


                                        return (
                                            <div className="card-recommend-item-card item" key={index}>
                                                <div className="card" onClick={() => clickStatusCard(index)}>
                                                    <div className="card-inner">
                                                        {
                                                            item.isShow == false && <div className="card-front" >
                                                                <div className="card-front-profile-control">
                                                                <span className="card-front-job-click-open">{languageConfig.recommend_modal.click_open}</span>
                                                                    <img src={PROFILE_AI_IMG} className="card-front-profile-img"></img>
                                                                    <span className="card-front-job-last-exp">{languageConfig.recommend_modal.last_exp}</span>
                                                                    <span className="card-front-job-title">{item.work_experience_job_title}</span>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className={
                                                            item.isShow && !item.isAnimationEnd && !item.used_credit ? "card-back-open" :

                                                                item.isShow && item.isAnimationEnd ? "card-back-active" : "card-back"
                                                        } onAnimationEnd={(e) => onAnimationEnd(e, index)}>
                                                            <div className=
                                                                {
                                                                    item.isAnimationEnd ? "card-back-profile-control" : "d-none"
                                                                }
                                                            >
                                                                <img src={PROFILE_AI_IMG} className="card-back-profile-img"></img>
                                                                {
                                                                    item.work_experience_job_title &&
                                                                    <span className="card-back-title">{languageConfig.recommend_modal.experience}
                                                                    </span>
                                                                }
                                                                {
                                                                    item.work_experience_job_title &&
                                                                    <span className="card-back-desc">{item.work_experience_job_title}
                                                                        {
                                                                            item.work_experience_company_name && <span> ที่ {item.work_experience_company_name}</span>
                                                                        }
                                                                    </span>
                                                                }

                                                                {
                                                                    item.work_experience_salary && <span className="card-back-desc">{languageConfig.recommend_modal.salary}: {item.work_experience_salary}
                                                                    </span>
                                                                }

                                                                {
                                                                    item.skills && item.skills.length > 0 &&
                                                                    <>
                                                                        <span className="card-back-title">{languageConfig.recommend_modal.skills}
                                                                        </span>
                                                                        <div className="d-inline-block card-back-desc-skill">
                                                                            {
                                                                                item.skills.map((items: string, index: number) => {
                                                                                    return (
                                                                                        <span key={index} >{items}
                                                                                            {
                                                                                                index != item.skills.length - 1 && ", "
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }



                                                                {
                                                                    item.used_credit ?
                                                                        <Button className="card-back-button-open" onClick={() => clickRecommendCandidate(item.candidate_id, true)}>
                                                                              {languageConfig.recommend_modal.view_info}
                                                                        </Button> :
                                                                        item.isConfirmPay ?
                                                                            <div className="card-back-button-control">
                                                                                <Button className="card-back-button-cancle" onClick={() => clickCancle(index)}>
                                                                                {languageConfig.recommend_modal.cancel}
                                                                                </Button>
                                                                                <Button className="card-back-button-open" onClick={() => clickRecommendCandidate(item.candidate_id, false)}>
                                                                                    <FontAwesomeIcon className="card-back-button-coin" icon={faCoins}></FontAwesomeIcon>
                                                                                    {languageConfig.recommend_modal.pay_credit}
                                                                                </Button>
                                                                            </div> :
                                                                            <Button className="card-back-button-open" onClick={() => clickConfirmPay(index)}>
                                                                                <FontAwesomeIcon className="card-back-button-coin" icon={faCoins}></FontAwesomeIcon>
                                                                               {languageConfig.recommend_modal.confirm_credit}
                                                                            </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }




                            </div> :

                            <div className="card-recommend-loading-control">
                                <div className="card-recommend-loading">
                                    <img src={AI_BOT_LOADING} className="card-recommend-loading-img">

                                    </img>
                                    <span className="card-recommend-loading-text">{languageConfig.loading}</span>

                                </div>

                            </div>

                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CardRecommendModal;