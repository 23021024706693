import { useEffect, useState } from "react";
import "./create-audience.scss";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from "@mui/material";
import AudienceModel, { ApplyJobBehavior, ClikBasedRetargeting, Experiences, ImpressBasedRetargeting, SearchJobBehavior, ViewJobBehavior } from "../../../../model/audience/audience.model";
import { AGE_LIST } from "../../../../enum/age-list";
import { APP_TYPE_LIST } from "../../../../enum/app-type-list";
import { LOCATION_LIST } from "../../../../enum/location-list";
import { PERIOD_LIST } from "../../../../enum/period-list";
import { FormLabel, Modal } from "react-bootstrap";
import JOB_ROLE_IDS from "../../../../assets/json/job_roles.json";
import { EDUCATION_LIST } from "../../../../enum/education-list";
import { EXPERIENCE_LIST } from "../../../../enum/experience-list";
import CRMServices from "../../../../services/crm.service";
import ErrorModal from "../../../../modal/error.modal";
import AudienceListModel from "../../../../model/audience/audience-list.model";
import BoardcastRetargetModel from "../../../../model/audience/audience-retarget.model";

const CreateAudienceComponent = ({ isOpen, handleClose, isEdit, id }: any) => {
    const [viewJobBehaviorRoleId, setViewJobBehaviorRoleId] = useState<number[]>([]);
    const [viewJobBehaviorRoleValue, setViewJobBehaviorRoleValue] = useState<string[]>([]);
    const [applyJobBehaviorRoleId, setApplyJobBehaviorRoleId] = useState<number[]>([]);
    const [applyJobBehaviorRoleValue, setApplyJobBehaviorRoleValue] = useState<string[]>([]);
    const [searchJobBehaviorRoleId, setSearchJobBehaviorRoleId] = useState<number[]>([]);
    const [searchJobBehaviorRoleValue, setSearchJobBehaviorRoleValue] = useState<string[]>([]);
    const [experienceRoleId, setExperienceRoleId] = useState<number[]>([]);
    const [experienceRoleValue, setExperienceRoleValue] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [boardcastRetarget, setBoardcastReatrget] = useState<BoardcastRetargetModel[]>();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [audienceObject, setAudienceObject] = useState<AudienceModel>();
    const [isClickBaseRetarget, setIsClickBaseRetarget] = useState(false);
    const [isImpressBaseRetarget, setIsImpressBaseRetarget] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isEditMod, setIsEdit] = useState(isEdit)

    useEffect(() => {
        if (isEditMod) {
            getEditInitialize(id);
        }
    }, [isEditMod])
    useEffect(() => {
        if (!audienceObject && !isEditMod) {
            const audienceMod: AudienceModel = {
                audience: {
                    age: [],
                    app_type: [],
                    education: [],
                    experiences: {} as Experiences,
                    gender: [],
                    location: [],
                    subsription_period: 0,

                },
                audience_name: ""
            }
            setAudienceObject(audienceMod);
            getRetargetID();
        }
    }, [audienceObject])

    useEffect(() => {

        if (viewJobBehaviorRoleValue) {
            const viewJobBehaviorRoleIdMod: number[] = new Array();
            JOB_ROLE_IDS.map((item: any, index: number) => {

                viewJobBehaviorRoleValue.map((items: string, indexs) => {
                    if (items == item.name) {
                        viewJobBehaviorRoleIdMod.push(item.id);
                    }
                })
            })
            setViewJobBehaviorRoleId(viewJobBehaviorRoleIdMod)
            if (audienceObject && viewJobBehaviorRoleIdMod ) {
                if (!audienceObject.audience.view_job_behavior) {
                    audienceObject.audience.view_job_behavior = {} as ViewJobBehavior;
                }
                audienceObject.audience.view_job_behavior.job_role_ids = viewJobBehaviorRoleIdMod

            }

        }
    }, [viewJobBehaviorRoleValue])

    useEffect(() => {

        if (searchJobBehaviorRoleValue) {
            const searchJobBehaviorRoleIdMod: number[] = new Array();
            JOB_ROLE_IDS.map((item: any, index: number) => {

                searchJobBehaviorRoleValue.map((items: string, indexs) => {
                    if (items == item.name) {
                        searchJobBehaviorRoleIdMod.push(item.id);
                    }
                })
            })
            setSearchJobBehaviorRoleId(searchJobBehaviorRoleIdMod)
            if (audienceObject && searchJobBehaviorRoleIdMod) {
                if(!audienceObject.audience.search_job_behavior){
                    audienceObject.audience.search_job_behavior = {} as SearchJobBehavior
                }
                audienceObject.audience.search_job_behavior.job_role_ids = searchJobBehaviorRoleIdMod
            }

        }
    }, [searchJobBehaviorRoleValue])


    useEffect(() => {

        if (applyJobBehaviorRoleValue) {
            const applyJobBehaviorRoleIdMod: number[] = new Array();
            JOB_ROLE_IDS.map((item: any, index: number) => {

                applyJobBehaviorRoleValue.map((items: string, indexs) => {
                    if (items == item.name) {
                        applyJobBehaviorRoleIdMod.push(item.id);
                    }
                })
            })
            setApplyJobBehaviorRoleId(applyJobBehaviorRoleIdMod)
            if (audienceObject && applyJobBehaviorRoleIdMod) {
                if(!audienceObject.audience.apply_job_behavior){
                    audienceObject.audience.apply_job_behavior = {} as ApplyJobBehavior
                }
                audienceObject.audience.apply_job_behavior.job_role_ids = applyJobBehaviorRoleIdMod
            }

        }
    }, [applyJobBehaviorRoleValue])
    useEffect(() => {

        if (experienceRoleValue) {
            const experienceRoleIdMod: number[] = new Array();
            JOB_ROLE_IDS.map((item: any, index: number) => {

                experienceRoleValue.map((items: string, indexs) => {
                    if (items == item.name) {
                        experienceRoleIdMod.push(item.id);
                    }
                })
            })
            setExperienceRoleId(experienceRoleIdMod)
            if (audienceObject) {
                audienceObject.audience.experiences.job_role_ids = experienceRoleIdMod
            }

        }
    }, [experienceRoleValue])

    const getEditInitialize = (id) => {
        CRMServices().getAudienceID(id).then((res) => {

            if (res) {
                const audienceRes = res as AudienceModel;
                console.log(audienceRes);
                if (audienceRes.audience.view_job_behavior) {
                    setViewJobBehaviorRoleId(audienceRes.audience.view_job_behavior.job_role_ids)
                    initialValuefromRoleIds(audienceRes.audience.view_job_behavior.job_role_ids, 'viewJobBehavior')
                }
                if (audienceRes.audience.search_job_behavior) {
                    setSearchJobBehaviorRoleId(audienceRes.audience.search_job_behavior.job_role_ids)
                    initialValuefromRoleIds(audienceRes.audience.search_job_behavior.job_role_ids, 'searchJobBehavior')
                }
                if (audienceRes.audience.apply_job_behavior) {
                    setApplyJobBehaviorRoleId(audienceRes.audience.apply_job_behavior.job_role_ids)
                    initialValuefromRoleIds(audienceRes.audience.apply_job_behavior.job_role_ids, 'applyJobBehavior')
                }
                if (audienceRes.audience.experiences) {
                    setExperienceRoleId(audienceRes.audience.experiences.job_role_ids)
                    initialValuefromRoleIds(audienceRes.audience.experiences.job_role_ids, 'experience')
                } else {
                    audienceRes.audience.experiences = {} as Experiences
                }

                if (audienceRes.audience.age) {
                    let arrayString: string[] = [];
                    AGE_LIST.map((item: any, index: number) => {
                        audienceRes.audience.age.map((items: any, indexs: number) => {
                            if (item.value == items) {
                                AGE_LIST[indexs].status = true
                                arrayString.push(item.value)
                            }
                        })
                    })
                    audienceRes.audience.age = arrayString;
                }
                if (audienceRes.audience.location) {
                    let arrayString: string[] = [];
                    LOCATION_LIST.map((item: any, index: number) => {
                        audienceRes.audience.location.map((items: any, indexs: number) => {
                            if (item.value == items) {
                                LOCATION_LIST[indexs].status = true
                                arrayString.push(item.value)
                            }
                        })
                    })
                    audienceRes.audience.location = arrayString;
                }
                if (audienceRes.audience.app_type) {
                    let arrayString: string[] = [];
                    APP_TYPE_LIST.map((item: any, index: number) => {
                        audienceRes.audience.app_type.map((items: any, indexs: number) => {
                            if (item.value == items) {
                                APP_TYPE_LIST[indexs].status = true
                                arrayString.push(item.value)
                            }
                        })
                    })
                    audienceRes.audience.app_type = arrayString;
                }
                if (audienceRes.audience.experiences) {
                    if (audienceRes.audience.experiences.years && audienceRes.audience.experiences.years.length > 0) {
                        let arrayString: string[] = [];
                        EXPERIENCE_LIST.map((item: any, index: number) => {
                            audienceRes.audience.experiences.years.map((items: any, indexs: number) => {
                                if (item.value == items) {
                                    EXPERIENCE_LIST[indexs].status = true
                                    arrayString.push(item.value)
                                }
                            })
                        })
                        audienceRes.audience.experiences.years = arrayString;
                    }

                }

                if (audienceRes.audience.clik_based_retargeting) {
                    if (audienceRes.audience.clik_based_retargeting.link_url || audienceRes.audience.clik_based_retargeting.message_id)
                        setIsClickBaseRetarget(true)
                }
                if (audienceRes.audience.impress_based_retargeting) {
                    if (audienceRes.audience.impress_based_retargeting.message_id)
                        setIsImpressBaseRetarget(true)
                }
                console.log(audienceRes)
                setAudienceObject(audienceRes);
            }
        })

    }

    const initialValuefromRoleIds = (objectArray: number[], action) => {
        let arrayString: string[] = [];
        objectArray.map((item: number, index: number) => {
            JOB_ROLE_IDS.map((items: any, indexs: number) => {
                if (item == items.id) {
                    arrayString.push(items.name)
                }
            })
        })
        if (action == 'viewJobBehavior') {
            setViewJobBehaviorRoleValue(arrayString)
        } else if (action == 'applyJobBehavior') {
            setApplyJobBehaviorRoleValue(arrayString)
        } else if (action == 'searchJobBehavior') {
            setSearchJobBehaviorRoleValue(arrayString)
        } else if (action == 'experience') {
            setExperienceRoleValue(arrayString)
        }
    }


    const getRetargetID = () => {

        CRMServices().getRetargetBroadcast().then((res) => {

            console.log(res);
            if (res.broadcasts) {
                res.broadcasts.map((items: any, index: number) => {
                    if (items.variants) {
                        let broadcastValue = [] as BoardcastRetargetModel[];
                        if (items.variants.length > 0) {
                            items.variants.map((item: any, index: number) => {

                                if (item.line_request_id) {
                                    let broadcastIndex: BoardcastRetargetModel = {
                                        key: item.line_request_id,
                                        name: items.broadcast_name
                                    }

                                    console.log(broadcastIndex);
                                    broadcastValue.push(broadcastIndex)
                                }
                            })
                            setBoardcastReatrget(broadcastValue);
                        }
                    }
                })

            }
        })
    }
    const handleChangeRoleId = (action, event) => {

        const {
            target: { value },
        } = event;
        if (action == 'viewJobBehavior') {
            setViewJobBehaviorRoleValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );

        } else if (action == 'applyJobBehavior') {
            setApplyJobBehaviorRoleValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        } else if (action == 'searchJobBehavior') {
            setSearchJobBehaviorRoleValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        } else if (action == 'experience') {
            setExperienceRoleValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        }

    };
    const onInputChange = (action, value) => {

        if (audienceObject) {
            const audienceModel = { ...audienceObject }
            if (action == 'clickBaseRetargetUrl') {
                audienceModel.audience.clik_based_retargeting = {} as ClikBasedRetargeting
                audienceModel.audience.clik_based_retargeting.link_url = value
            } else if (action == "name") {
                audienceModel.audience_name = value;
            }
            setAudienceObject(audienceModel);
        }

    }

    const onCheck = (action, index, value) => {
        if (audienceObject) {
            const audienceModel = { ...audienceObject }
            if (action == 'GENDER') {
                if (index == 0) {
                    if (value) {
                        audienceModel.audience.gender.push("MALE");
                    } else {
                        var indexArray = audienceModel.audience.gender.indexOf("MALE");
                        if (indexArray !== -1) {
                            audienceModel.audience.gender.splice(indexArray, 1);
                        }
                    }
                } else {
                    if (value) {
                        audienceModel.audience.gender.push("FEMALE");
                    } else {
                        var indexArray = audienceModel.audience.gender.indexOf("FEMALE");
                        if (indexArray !== -1) {
                            audienceModel.audience.gender.splice(indexArray, 1);
                        }
                    }
                }
            } else if (action == "AGELIST") {
                AGE_LIST[index].status = value
                let ageValue = new Array();
                AGE_LIST.forEach(item => {
                    if (item.status) {
                        ageValue.push(item.value)
                    }
                })
                audienceModel.audience.age = ageValue;
            } else if (action == "APPTYPE_LIST") {
                APP_TYPE_LIST[index].status = value
                let valueList = new Array();
                APP_TYPE_LIST.forEach(item => {
                    if (item.status) {
                        valueList.push(item.value)
                    }
                })
                audienceModel.audience.app_type = valueList;
            } else if (action == "LOCATION_LIST") {
                LOCATION_LIST[index].status = value
                let valueList = new Array();
                LOCATION_LIST.forEach(item => {
                    if (item.status) {
                        valueList.push(item.value)
                    }
                })
                audienceModel.audience.location = valueList;
            } else if (action == "CLICKBASE_RETARGET") {
                setIsClickBaseRetarget(value);
                audienceModel.audience.clik_based_retargeting = {} as ClikBasedRetargeting;
            } else if (action == "IMPRESSBASE_RETARGET") {
                setIsImpressBaseRetarget(value);
                audienceModel.audience.impress_based_retargeting = {} as ImpressBasedRetargeting;
            } else if (action == "education") {
                EDUCATION_LIST[index].status = value
                let valueList = new Array();
                EDUCATION_LIST.forEach(item => {
                    if (item.status) {
                        valueList.push(item.value)
                    }
                })
                audienceModel.audience.education = valueList;
            } else if (action == 'experience') {
                EXPERIENCE_LIST[index].status = value
                let valueList = new Array();
                EXPERIENCE_LIST.forEach(item => {
                    if (item.status) {
                        valueList.push(item.value)
                    }
                })
                audienceModel.audience.experiences.years = valueList;
            }


            setAudienceObject(audienceModel);
        }

    }

    const submit = () => {


        if (audienceObject) {
            setIsSubmit(true);
            if (audienceObject.audience_name) {
                setIsLoading(true);
                if (isEdit) {
                    console.log(audienceObject);
                    const updateAudience: AudienceModel = {
                        audience: audienceObject.audience,
                        audience_name: audienceObject.audience_name
                    }
                    CRMServices().updateAudience(updateAudience, audienceObject.audience_uuid).then((res) => {
                        console.log(res);
                        if (res) {
                            handleClose();
                            setIsError(false);
                        } else {
                            if (res.response.status != 200) {
                                setIsError(true);
                                setIsLoading(false);
                            }
                        }
                    })
                } else {
                    console.log(audienceObject);
                    CRMServices().createAudience(audienceObject).then((res) => {
                        console.log(res);
                        if (res) {
                            handleClose();
                            setIsError(false);
                        } else {
                            if (res.response.status != 200) {
                                setIsError(true);
                                setIsLoading(false);
                            }
                        }
                    })
                }

            }

        }

    }

    const onRadioCheck = (action, value) => {
        if (audienceObject) {
            const audienceModel = { ...audienceObject }
            if (action == 'subscriptionPeriod') {
                console.log(value);
                audienceModel.audience.subsription_period = Number(value);
            } else if (action == 'viewJobBehavior' && value) {
                if(!audienceModel.audience.view_job_behavior){
                    audienceModel.audience.view_job_behavior = {} as ViewJobBehavior
                }
                audienceModel.audience.view_job_behavior.period = Number(value);
            } else if (action == 'applyJobBehavior' && value) {
                if(!audienceModel.audience.apply_job_behavior){
                    audienceModel.audience.apply_job_behavior = {} as ApplyJobBehavior
                }
                audienceModel.audience.apply_job_behavior.period = Number(value);
            } else if (action == 'searchJobBehavior' && value) {
                if(!audienceModel.audience.search_job_behavior){
                    audienceModel.audience.search_job_behavior = {} as SearchJobBehavior
                }
                audienceModel.audience.search_job_behavior.period = Number(value);
            }
            setAudienceObject(audienceModel);
        }
    }

    const onSelectChange = (action, value) => {
        if (audienceObject) {
            const audienceModel = { ...audienceObject }
            if (action == 'clickBaseRetargetPush') {
                audienceModel.audience.clik_based_retargeting = {} as ClikBasedRetargeting
                audienceModel.audience.clik_based_retargeting.message_id = value;
            } else if (action == 'clickBaseRetargetLink') {
                audienceModel.audience.clik_based_retargeting = {} as ClikBasedRetargeting
                audienceModel.audience.clik_based_retargeting.link_url = value;
            }
            if (action == 'clickImpressBaseRetargetPush') {
                audienceModel.audience.impress_based_retargeting = {} as ImpressBasedRetargeting
                audienceModel.audience.impress_based_retargeting.message_id = value;
            }

            if (action == 'viewJobBehavior' && value) {
                if(!audienceModel.audience.view_job_behavior){
                    audienceModel.audience.view_job_behavior = {} as ViewJobBehavior
                }
                audienceModel.audience.view_job_behavior.job_role_ids = value;
            } else if (action == 'applyJobBehavior' && value) {
                if(!audienceModel.audience.apply_job_behavior){
                    audienceModel.audience.apply_job_behavior = {} as ApplyJobBehavior
                }
                audienceModel.audience.apply_job_behavior.job_role_ids = value;
            } else if (action == 'searchJobBehavior' && value) {
                if(!audienceModel.audience.search_job_behavior){
                    audienceModel.audience.search_job_behavior = {} as SearchJobBehavior
                }
                audienceModel.audience.search_job_behavior.job_role_ids = value;
            } else if (action == 'experience') {
                audienceModel.audience.experiences.job_role_ids = value;
            }
            setAudienceObject(audienceModel);
        }

    }

    const handleCloseError = () => {
        setIsError(false);
    }

    const clickDelete = (id) => {
        CRMServices().deleteAudience(id).then((res) => {

            if (res) {
                handleClose();
            }
        })

    }



    return (

        <div className="audience-create-modal">
            <Modal show={isOpen} onHide={handleClose} className="audience-create-modal" backdrop={'static'} >
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'แก้ไข' : 'สร้าง'} Audience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        audienceObject && !isLoading ?

                            <div className="create-audience">
                                <div className="create-audience-name-control">
                                    <span className="create-audience-headline">ชื่อ Audience</span>
                                    <FormControl className="create-audience-form-margin">

                                        <TextField id="standard-basic" value={audienceObject.audience_name} onChange={(e) => onInputChange('name', e.target.value)}
                                            variant="outlined" />
                                        {
                                            isSubmit && !audienceObject.audience_name &&
                                            <span className="create-audience-required-error">กรุณากรอกชื่อ</span>
                                        }
                                    </FormControl>
                                </div>
                                <div></div>
                                <div className="create-audience-line-oa">
                                    <span className="create-audience-headline">LINE OA</span>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">GENDER</span>
                                        <FormGroup>

                                            <FormControlLabel control={<Checkbox
                                                defaultChecked={
                                                    audienceObject.audience.gender &&
                                                        audienceObject.audience.gender.filter((item) => item == "MALE")[0] == "MALE" ? true : false
                                                }
                                                onChange={(e) => onCheck('GENDER', '0', e.target.checked)} value={"MALE"} />} label="MALE" />
                                            <FormControlLabel control={<Checkbox defaultChecked={
                                                audienceObject.audience.gender &&
                                                    audienceObject.audience.gender.filter((item) => item == "FEMALE")[0] == "FEMALE" ? true : false
                                            }
                                                onChange={(e) => onCheck('GENDER', '1', e.target.checked)} value={"FEMALE"} />} label="FEMALE" />
                                        </FormGroup>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">AGE</span>
                                        <FormGroup className="create-audience-form-control-flex">
                                            {
                                                AGE_LIST.map((item: any, index: number) => {


                                                    return (
                                                        <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                            control={<Checkbox
                                                                defaultChecked={
                                                                    audienceObject.audience.age &&
                                                                        audienceObject.audience.age.filter((items) =>
                                                                            items == item.value).length > 0 ? true : false
                                                                }
                                                                onChange={(e) => onCheck('AGELIST', index, e.target.checked)} />}
                                                            label={item.name + " ปี"} />
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">App Type</span>
                                        <FormGroup className="create-audience-form-control-flex">
                                            {
                                                APP_TYPE_LIST.map((item: any, index: number) => {


                                                    return (
                                                        <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                            control={<Checkbox
                                                                defaultChecked={
                                                                    audienceObject.audience.app_type &&
                                                                        audienceObject.audience.app_type.filter((items) =>
                                                                            items == item.value).length > 0 ? true : false
                                                                }
                                                                onChange={(e) => onCheck('APPTYPE_LIST', index, e.target.checked)} />}
                                                            label={item.name} />
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Location</span>
                                        <FormGroup className="create-audience-form-control-flex">
                                            {
                                                LOCATION_LIST.map((item: any, index: number) => {


                                                    return (
                                                        <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                            control={<Checkbox
                                                                defaultChecked={
                                                                    audienceObject.audience.location &&
                                                                        audienceObject.audience.location.filter((items) =>
                                                                            items == item.value).length > 0 ? true : false
                                                                }
                                                                onChange={(e) => onCheck('LOCATION_LIST', index, e.target.checked)} />}
                                                            label={item.name} />
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Subscription period</span>
                                        <FormControl className="create-audience-form-control-flex">

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                className="create-audience-radio-flex-row"
                                                value={audienceObject.audience.subsription_period}
                                                onChange={(e) => onRadioCheck('subscriptionPeriod', e.target.value)}
                                            >
                                                {
                                                    PERIOD_LIST.map((item: any, index: number) => {


                                                        return (
                                                            <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                                value={item.value}
                                                                control={<Radio />}
                                                                label={item.name} />
                                                        )
                                                    })
                                                }

                                            </RadioGroup>
                                        </FormControl>

                                    </div>
                                    <div className="create-audience-form-control">
                                        <FormGroup className="create-audience-form-control-flex">
                                            <FormControlLabel className="create-audience-form-checkbox"
                                                control={<Checkbox
                                                    defaultChecked={
                                                        audienceObject.audience.clik_based_retargeting && true
                                                    }
                                                    onChange={(e) => onCheck('CLICKBASE_RETARGET', 0, e.target.checked)} />}
                                                label="Click based retargeting" />
                                        </FormGroup>
                                        {
                                            isClickBaseRetarget && audienceObject &&
                                            <div className="d-flex flex-column">
                                                <FormControl className="create-audience-form-margin">
                                                    <InputLabel id="demo-simple-select-label">Push message id</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={
                                                            audienceObject.audience.clik_based_retargeting &&
                                                            audienceObject.audience.clik_based_retargeting.message_id}
                                                        label="Push message id"
                                                        onChange={(e) => onSelectChange('clickBaseRetargetPush', e.target.value)}
                                                    >
                                                        {
                                                            boardcastRetarget &&
                                                            boardcastRetarget.map((item: BoardcastRetargetModel, index: number) => {

                                                                return (
                                                                    <MenuItem key={index} value={item.key}>{item.name} : ( {item.key} )</MenuItem>
                                                                )
                                                            })

                                                        }
                                                    </Select>
                                                </FormControl>
                                                <FormControl className="create-audience-form-margin">

                                                    <TextField id="standard-basic"
                                                        value={audienceObject.audience.clik_based_retargeting &&
                                                            audienceObject.audience.clik_based_retargeting.link_url}
                                                        onChange={(e) => onInputChange('clickBaseRetargetUrl', e.target.value)} label="Link Url" variant="outlined" />
                                                </FormControl>
                                            </div>
                                        }

                                    </div>
                                    <div className="create-audience-form-control">
                                        <FormGroup className="create-audience-form-control-flex">
                                            <FormControlLabel className="create-audience-form-checkbox"
                                                control={<Checkbox
                                                    defaultChecked={
                                                        audienceObject.audience.impress_based_retargeting && true
                                                    }
                                                    onChange={(e) => onCheck('IMPRESSBASE_RETARGET', 0, e.target.checked)} />}
                                                label="Impress based retargeting" />
                                        </FormGroup>
                                        {
                                            isImpressBaseRetarget && audienceObject &&
                                            <div className="d-flex flex-column">
                                                <FormControl className="create-audience-form-margin">
                                                    <InputLabel id="demo-simple-select-label">Push message id</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={
                                                            audienceObject.audience.impress_based_retargeting &&
                                                            audienceObject.audience.impress_based_retargeting.message_id}
                                                        label="Push message id"
                                                        onChange={(e) => onSelectChange('clickImpressBaseRetargetPush', e.target.value)}
                                                    >
                                                        {
                                                            boardcastRetarget &&
                                                            boardcastRetarget.map((item: BoardcastRetargetModel, index: number) => {

                                                                return (
                                                                    <MenuItem key={index} value={item.key}>{item.name} : ( {item.key} )</MenuItem>
                                                                )
                                                            })

                                                        }

                                                    </Select>
                                                </FormControl>

                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="create-audience-line-oa">
                                    <span className="create-audience-headline">Interest of JobNOW Users</span>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">View job behavior</span>
                                        <FormControl className="create-audience-form-margin">
                                            <InputLabel id="demo-simple-select-label">ประเภทงาน</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={viewJobBehaviorRoleValue}
                                                onChange={(e) => handleChangeRoleId('viewJobBehavior', e)}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {JOB_ROLE_IDS.map((item: any, index: number) => (
                                                    <MenuItem key={index} value={item.name}>
                                                        <Checkbox checked={viewJobBehaviorRoleValue.indexOf(item.name) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="create-audience-tag-control">
                                                {
                                                    viewJobBehaviorRoleValue.map((item: any, index: number) => {

                                                        return (
                                                            index == viewJobBehaviorRoleValue.length - 1 ?
                                                                <span className="crete-audience-tag-value">{item}</span> : <span className="crete-audience-tag-value">{item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </FormControl>
                                        <FormControl className="create-audience-form-margin">
                                            <span className="create-audience-title-form">Period (days)</span>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                className="create-audience-radio-flex-row"
                                                value={audienceObject.audience.view_job_behavior && audienceObject.audience.view_job_behavior.period}
                                                onChange={(e) => onRadioCheck('viewJobBehavior', e.target.value)}
                                            >
                                                {
                                                    PERIOD_LIST.map((item: any, index: number) => {


                                                        return (
                                                            <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                                value={item.value}
                                                                control={<Radio />}
                                                                label={item.name} />
                                                        )
                                                    })
                                                }

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Apply job behavior</span>
                                        <FormControl className="create-audience-form-margin">
                                            <InputLabel id="demo-simple-select-label">ประเภทงาน</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={applyJobBehaviorRoleValue}
                                                onChange={(e) => handleChangeRoleId('applyJobBehavior', e)}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {JOB_ROLE_IDS.map((item: any, index: number) => (
                                                    <MenuItem key={index} value={item.name}>
                                                        <Checkbox checked={applyJobBehaviorRoleValue.indexOf(item.name) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="create-audience-tag-control">
                                                {
                                                    applyJobBehaviorRoleValue.map((item: any, index: number) => {

                                                        return (
                                                            index == applyJobBehaviorRoleValue.length - 1 ?
                                                                <span className="crete-audience-tag-value">{item}</span> : <span className="crete-audience-tag-value">{item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </FormControl>
                                        <FormControl className="create-audience-form-margin">
                                            <span className="create-audience-title-form">Period (days)</span>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                className="create-audience-radio-flex-row"
                                                value={audienceObject.audience.apply_job_behavior && audienceObject.audience.apply_job_behavior.period}
                                                onChange={(e) => onRadioCheck('applyJobBehavior', e.target.value)}
                                            >
                                                {
                                                    PERIOD_LIST.map((item: any, index: number) => {


                                                        return (
                                                            <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                                value={item.value}
                                                                control={<Radio />}
                                                                label={item.name} />
                                                        )
                                                    })
                                                }

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Search job behavior</span>
                                        <FormControl className="create-audience-form-margin">
                                            <InputLabel id="demo-simple-select-label">ประเภทงาน</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={searchJobBehaviorRoleValue}
                                                onChange={(e) => handleChangeRoleId('searchJobBehavior', e)}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {JOB_ROLE_IDS.map((item: any, index: number) => (
                                                    <MenuItem key={index} value={item.name}>
                                                        <Checkbox checked={searchJobBehaviorRoleValue.indexOf(item.name) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="create-audience-tag-control">
                                                {
                                                    searchJobBehaviorRoleValue.map((item: any, index: number) => {

                                                        return (
                                                            index == searchJobBehaviorRoleValue.length - 1 ?
                                                                <span className="crete-audience-tag-value">{item}</span> : <span className="crete-audience-tag-value">{item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </FormControl>
                                        <FormControl className="create-audience-form-margin">
                                            <span className="create-audience-title-form">Period (days)</span>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                className="create-audience-radio-flex-row"
                                                value={audienceObject.audience.search_job_behavior && audienceObject.audience.search_job_behavior.period}
                                                onChange={(e) => onRadioCheck('searchJobBehavior', e.target.value)}
                                            >
                                                {
                                                    PERIOD_LIST.map((item: any, index: number) => {


                                                        return (
                                                            <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                                value={item.value}
                                                                control={<Radio />}
                                                                label={item.name} />
                                                        )
                                                    })
                                                }

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Education</span>
                                        <FormGroup className="create-audience-form-control-flex">
                                            {
                                                EDUCATION_LIST.map((item: any, index: number) => {


                                                    return (
                                                        <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                            control={<Checkbox
                                                                defaultChecked={
                                                                    audienceObject.audience.education &&
                                                                        audienceObject.audience.education.filter((items) =>
                                                                            items == item.value).length > 0 ? true : false
                                                                }
                                                                onChange={(e) => onCheck('education', index, e.target.checked)} />}
                                                            label={item.name} />
                                                    )
                                                })
                                            }
                                        </FormGroup>

                                    </div>
                                    <div className="create-audience-form-control">
                                        <span className="create-audience-title-form">Experience</span>
                                        <FormControl className="create-audience-form-margin">
                                            <InputLabel id="demo-simple-select-label">ประเภทงาน</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={experienceRoleValue}
                                                onChange={(e) => handleChangeRoleId('experience', e)}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {JOB_ROLE_IDS.map((item: any, index: number) => (
                                                    <MenuItem key={index} value={item.name}>
                                                        <Checkbox checked={experienceRoleValue.indexOf(item.name) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="create-audience-tag-control">
                                                {
                                                    experienceRoleValue.map((item: any, index: number) => {

                                                        return (
                                                            index == experienceRoleValue.length - 1 ?
                                                                <span className="crete-audience-tag-value">{item}</span> : <span className="crete-audience-tag-value">{item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </FormControl>
                                        <FormGroup className="create-audience-form-control-flex">
                                            {
                                                EXPERIENCE_LIST.map((item: any, index: number) => {


                                                    return (
                                                        <FormControlLabel className="create-audience-form-checkbox" key={index}
                                                            control={<Checkbox
                                                                defaultChecked={
                                                                    (audienceObject.audience.experiences.years) &&
                                                                        audienceObject.audience.experiences.years.filter((items) =>
                                                                            items == item.value).length > 0 ? true : false
                                                                }
                                                                onChange={(e) => onCheck('experience', index, e.target.checked)} />}
                                                            label={item.name} />
                                                    )
                                                })
                                            }
                                        </FormGroup>

                                    </div>
                                </div>
                                <div>
                                    <Button className="create-audience-button-submit" onClick={submit}>{
                                        isEdit ? "Update" : "Submit"}</Button>

                                    {
                                        isEdit && <Button className="create-audience-button-delete" onClick={() => clickDelete(audienceObject.audience_uuid)}>Delete</Button>
                                    }

                                </div>



                            </div > :
                            <div className="create-audience-loading-control">
                                <div className="create-audience-loading-progress-control">
                                    <CircularProgress className="create-audience-loading-progress"></CircularProgress>
                                    <span className="create-audience-loading-text">กำลังโหลด</span>
                                </div>
                            </div>
                    }
                    {
                        isLoading && <div className="create-audience-loading-control">
                            <div className="create-audience-loading-progress-control">
                                <CircularProgress className="create-audience-loading-progress"></CircularProgress>
                                <span className="create-audience-loading-text">กำลังโหลด</span>
                            </div>
                        </div>
                    }
                </Modal.Body>

            </Modal>


            {
                isError &&
                <ErrorModal isOpen={isError} handleClose={handleCloseError}></ErrorModal>
            }
        </div>

    )
}

export default CreateAudienceComponent;