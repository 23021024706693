import { Tab, Tabs } from "@mui/material";
import "./crm.scss";
import { useEffect, useState } from "react";
import ProfileSCModel from "../../../model/profile-sc.model";
import CreateAudienceComponent from "./audience/create-audience";
import AudienceListComponent from "./audience/list/audience-list";
import CampaignListComponent from "./campaign/list/campaign-list";
import templateConfig from "../../../template-config";

const CRMComponent = ({ profileModel }: any) => {

    const [currentTab, setCurrentTab] = useState("one");
    const [profileMod, setProfileMod] = useState<ProfileSCModel>();

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
        }

    }, [profileModel])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }
    return (

        <div className="crm-control">
            {
                profileMod &&
                <>

                    <Tabs
                        value={currentTab}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="primary"
                        TabIndicatorProps={{style: {
                           backgroundColor: "var(--primary-color)"
                        }}}
                        className="crm-tab-custom"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label="Campaign Actived" />
                        <Tab value="two" label="Audience" />
                    </Tabs>

                    <div className="crm-container-desc">

                        {
                            currentTab == "two" && <AudienceListComponent profileModel={profileMod}></AudienceListComponent>
                        }
                        {
                            currentTab == "one" && <CampaignListComponent  profileModel={profileMod}></CampaignListComponent>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default CRMComponent;