import axios from "axios"
import apiConfig from "../api-config"
import config from "../config"
import tokenService from "./token.service"



export default function CRMServices() {

    return {
        async createAudience(objectResult) {

            return axios.post(config.crm.base_url + apiConfig.crm.audiences, objectResult, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)

        },
        async updateAudience(objectResult, audience_uuid) {
            return axios.put(config.crm.base_url + apiConfig.crm.audiences + "/" + audience_uuid, objectResult, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },
        async deleteAudience(audience_uuid) {
            return axios.delete(config.crm.base_url + apiConfig.crm.audiences + "/" + audience_uuid, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },
        async getAudienceAll() {

            return axios.get(config.crm.base_url + apiConfig.crm.audiences, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)

        },


        async getAudienceID(id) {
            return axios.get(config.crm.base_url + apiConfig.crm.audiences + "/" + id, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },
        async getCampaignAll() {

            return axios.get(config.crm.base_url + apiConfig.crm.broadcasts, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },

        async getRetargetBroadcast() {
            return axios.get(config.crm.base_url + apiConfig.crm.broadcasts + apiConfig.crm.requests, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },

        async createCampaign(objectResult) {
            return axios.post(config.crm.base_url + apiConfig.crm.broadcasts, objectResult, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },
        async getInsightReachVolume() {
            return axios.get(config.crm.base_url + apiConfig.crm.insight, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)
        },
        async getCampaignDetailById(id) {
            return axios.get(config.crm.base_url + apiConfig.crm.broadcasts + "/" + id, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)

        },async getSheetsImport(sheetID) {
            const objectResult = {
                sheet_id: sheetID
            }
            return axios.post(config.crm.base_url + apiConfig.crm.audiences + apiConfig.crm.sheet_import,objectResult, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)

        }
        ,async postSheetsAudience(objectResult) {
            return axios.post(config.crm.base_url + apiConfig.crm.audiences + apiConfig.crm.sheet,objectResult, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => err)

        }


    }
}