import { OauthModel } from "../model/oauth.model";
import ProfileSCModel from "../model/profile-sc.model";
import storage from "../storage";

class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.refresh_token;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.access_token;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      user.access_token = token;
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
    getProfile() {
      return JSON.parse(localStorage.getItem(storage.profile)!) as ProfileSCModel;
    }
  
    setProfile(profile) {
      localStorage.setItem(storage.profile, JSON.stringify(profile));
    }
    getUser() {
      return JSON.parse(localStorage.getItem(storage.profile)!) as OauthModel;
    }
  
    setUser(user) {
      localStorage.setItem(storage.user, JSON.stringify(user));
    }

    setCode(code) {
      localStorage.setItem(storage.code, code);
    }
    setVerifyCode(code) {
      localStorage.setItem(storage.code_verifier, code);
    }
    getVerifyCode() {
      return localStorage.getItem(storage.code_verifier);
    }
    getCode() {
      return localStorage.getItem(storage.code);
    }

    getGuest(){

      return localStorage.getItem(storage.isguest);
    }

    setGuest(isGuest){
      localStorage.setItem(storage.isguest, isGuest);
    }
  
    removeUser() {
      localStorage.removeItem(storage.user);
    }
  }
  
  export default new TokenService();