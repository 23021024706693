import "./articles.scss";
import ARTICLE_ICON from "../../../../assets/images/article-icon.png";
import { useLanguage } from "../../../../context/language.context";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import JSON_LANG_TH from "../../../../assets/json/talent-sauce/landing-th.json";
import JSON_LANG_EN from "../../../../assets/json/talent-sauce/landing-en.json";
import languageService from "../../../../services/language.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ArticlesServices from "../../../../services/articles.service";
import { PostArticleModel } from "../../../../model/articles.model";
import config from "../../../../config";
import UtilityService from "../../../../utils/utilities";
const ArticlesComponent = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [translate, setTranslate] = useState(JSON_LANG_EN);
    const [articleRecommend, setArticleRecommend] = useState<PostArticleModel[]>([]);
    useEffect(() => {
        if (languageService.getLanguage() == 'en') {
            setTranslate(JSON_LANG_EN)
        } else {
            setTranslate(JSON_LANG_TH)
        }
        window.onload = function () {
            window.scrollTo(0, 0);
        };
        window.scrollTo(0, 0);
        getRecommend();
    }, [])

    const getRecommend = async () => {
        ArticlesServices().getRecommend("scoutout").then((res) => {
            if (res) {

                if (res.status == 200) {

                    if (res.data) {
                        const result = res.data.result as PostArticleModel[];
                        const randomItem = getRandomItems(result, 3);
                        setArticleRecommend(randomItem);
                    }
                }
            }
        })


    }
    const clickAllArticle = () =>{
        UtilityService().clickSendEvent("click_view_all_article", "click");
        window.open(config.articles.view_url , "_blank");
    }
    const openArticle = (id) =>{
        UtilityService().clickSendEvent("click_view_article_"+id, "click");
        window.open(config.articles.view_url + "articles/"+id , "_blank");
    }
    const getRandomItems = (items: any[], count: number) => {
        // Shuffle array using the Fisher-Yates algorithm
        const shuffled = items
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);

        // Get the first `count` elements
        return shuffled.slice(0, count);
    }
    return (

        <>
        {
            articleRecommend && 
            <div className="articles-main">
                <img src={ARTICLE_ICON} className="articles-icon"></img>
                <span className="articles-title">{translate.cover_section.recent_articles}</span>
                <div className="articles-grid-control">
                    {
                        articleRecommend.map((item: PostArticleModel, index: number) => {

                            return (
                                <div className="articles-grid-item" key={index} onClick={() => openArticle(item.uuid)}>
                                <img className="articles-grid-img" src={item.seo.cover_img}></img>
                                <div className="articles-grid-text-control">
                                    <span className="articles-grid-title">{item.seo.title}</span>
                                    <span className="articles-grid-desc">{item.seo.description}</span>
                                </div>
                            </div>
                            )
                        })
                    }
                   
                </div>
                <div className="articles-all-control" onClick={clickAllArticle}>
                    <span className="articles-all-text">
                        {translate.cover_section.view_all_articles}
                    </span>
                    <FontAwesomeIcon icon={faArrowRight} className="articles-all-icon"></FontAwesomeIcon>
                </div>
            </div>
        }
            
        </>
    )
}

export default ArticlesComponent;