import { Modal, ModalBody, ModalHeader } from "react-bootstrap"
import "./credit-usage.modal.scss";
import config from "../config";
import apiConfig from "../api-config";
import { Divider } from "@mui/material";

import CREDIT_USAGE_TS_TH from "../assets/json/talent-sauce/credit-usage-th.json";
import CREDIT_USAGE_TS from "../assets/json/talent-sauce/credit-usage.json"
import CREDIT_USAGE_TH from "../assets/json/credit-usage-th.json"
import CREDIT_USAGE from "../assets/json/credit-usage.json"
import { useEffect, useState } from "react";
import languageService from "../services/language.service";

const CreditUsageModal = ({ open, handleClose }: any) => {

    const [credit, setCredit] = useState(CREDIT_USAGE);

    useEffect(() => {
        if (languageService.getLanguage() == 'en') {
            if (config.app_name == "HR9CLINIC") {
                setCredit(CREDIT_USAGE)
            } else {
                setCredit(CREDIT_USAGE_TS)
            }
        } else {
            if (config.app_name == "HR9CLINIC") {
                setCredit(CREDIT_USAGE_TH)
            } else {
                setCredit(CREDIT_USAGE_TS_TH)
            }
        }
        window.onload = function () {
            window.scrollTo(0, 0);
        };
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {


    }, [])


    return (
        <>
            <div className="credit-usage-modal">
                <Modal show={open} onHide={handleClose} className="credit-usage-modal">
                    <ModalHeader closeButton>

                    </ModalHeader>
                    <ModalBody>

                        <div className="credit-usage-control">

                            <span className="credit-usage-header">

                                คู่มือการใช้เครดิตสำหรับ {config.app_name}

                            </span>
                            <span className="credit-usage-desc">
                                ยินดีต้อนรับสู่ {config.app_name}
                                ที่เราจะช่วยทำให้กระบวนการสรรหางานของคุณง่ายขึ้นด้วยฟีเจอร์ขั้นสูง ดังนั้นเพื่อให้คุณใช้บริการของเราได้อย่างมีประสิทธิภาพที่สุด เราขอนำเสนอคู่มือเกี่ยวกับการใช้เครดิตสำหรับแต่ละฟีเจอร์:
                            </span>
                            <Divider className="credit-usage-divider"></Divider>
                            <div className="credit-usage-desc-control">
                                {
                                    credit.map((item, index) => {

                                        return (

                                            <>
                                                <span className="credit-usage-desc-bold">
                                                    {index + 1}. {item.title}

                                                </span>
                                                <span className="credit-usage-desc" dangerouslySetInnerHTML={{ __html: item.html_desc }}>

                                                </span>
                                                <Divider className="credit-usage-divider"></Divider>
                                            </>
                                        )
                                    })
                                }

                            </div>

                        </div>

                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default CreditUsageModal;