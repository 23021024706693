export const AGE_LIST = [{
    name: "15-20", value: "15-20", status: false
}, {
    name: "21-25", value: "21-25", status: false
},
{
    name: "26-30", value: "26-30", status: false
},
{
    name: "31-35", value: "31-35", status: false
},
{
    name: "36-40", value: "36-40", status: false
},
{
    name: "41-45", value: "41-45", status: false
},
{
    name: "46-50", value: "46-50", status: false
},
{
    name: "50+", value: "50+", status: false
}]