import "./loading.scss";
import Lottie from "lottie-react";
import SearchingAnimation from "../../../assets/json/loading-blue.json";
import { useEffect, useState } from "react";

const LoadingComponent = ({listTrick, feature} :any) => {

    const [message, setMessage] = useState("");
    const [index, setIndex] = useState(0)
    useEffect(() => {
        setMessage(listTrick.trick[index])
        const interval = setInterval(() => {
            setMessage(listTrick.trick[index])
            if((index + 1) == listTrick.trick.length){
                setIndex(0);
            }else{
                setIndex(index + 1)
            }
           
          }, 5000)
      
        return (() => clearInterval(interval)) //This is a cleanup function
      })
      


    return (

        <>
            <div className="loading-comp">

                <div className="loading-control">
                    <Lottie animationData={SearchingAnimation} loop={true} className="loading-lottie" />
                    <span className="loading-text">{feature}</span>
                    <span className="loading-trick-text"><b>*{listTrick.name} :</b> {message}</span>
                </div>
            </div>

        </>
    )
}

export default LoadingComponent;