import axios from "axios";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import SearchingAnimation from "../../assets/json/loading-blue.json";
import "./check-login.scss";
import GAConfig from "../../ga-config";
import UtilityService from "../../utils/utilities";
import ProfileSCModel from "../../model/profile-sc.model";
import tokenService from "../../services/token.service";
import CompanyProfileModel from "../../model/company-profile.model";
import { ProviderContext } from "../../context/provider.context";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import AuthenMysa from "../../services/authen-mysa";
import GPTService from "../../services/gpt.service";
const CheckLoginComponent = () => {

    const navigate = useNavigate();
    const { profileModel, creditModel, setProfileModel, setCreditModel }: any = useContext(ProviderContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { utmSource, utmMedium, version, redirect, feature } = getUTMParams(location);
    useEffect(() => {
        if (searchParams.get('code') && searchParams.get('state')) {
            tokenService.setCode(searchParams.get('code') + "");
            checkAuthenMysa(searchParams.get('code') + "");
        } else if (tokenService.getUser()) {
            console.log(tokenService.getUser());
            getProfile();
        } else {
            if (redirect) {
                navigate("/login?redirect=" + redirect);
            } else {
                navigate("/login")
            }

        }

    }, [])

    const checkAuthenMysa = async (code) => {

        const accessTokenResult = await AuthenMysa().getAccessToken(code, tokenService.getVerifyCode(), utmSource, utmMedium, feature);
        if (accessTokenResult) {
            tokenService.setUser(accessTokenResult);
            getProfile();
        }
    }

    const getProfile = async () => {
        const profileResult = await AuthenMysa().getProfile();
        if (profileResult) {
            UtilityService().clickSendEvent("login_success", "click")
            setProfileModel(profileResult["data"]);
            const profileModels = profileResult["data"];
            getCompanyProfile(profileModels);

        } else {
            console.log(profileResult);
            RegisterProfile();
        }
    }
    const RegisterProfile = () => {
        AuthenMysa().registerProfile().then((res) => {
            console.log(res);
            if (res) {
                getProfile();
            } else {
                localStorage.clear();
                handleNavigation(navigate, '/', utmSource, utmMedium, feature);
            }
        })
    }
    const clickStartFree = async () => {
        UtilityService().clickSendEvent("start_free_trial", "click")

        const res = await axios.get("https://api.ipify.org/?format=json");

        if (res.data.ip) {
            const profileGuest: ProfileSCModel = {
                email: "guest",
                firstName: "guest",
                lastName: "",
                isGuest: true,
                uuid: res.data.ip,
                companyManageResponses: []
            }

            getCompanyProfile(profileGuest);
        } else {
            alert("กรุณาเช็คการเชื่อมต่อ");
        }
    }

    const getCompanyProfile = (profileModels) => {
        GPTService.getCompanyProfile(profileModels.uuid).then((res) => {
            if (res) {
                const profileMod: ProfileSCModel = profileModels;
                const result = res.data as CompanyProfileModel;
                profileMod.company_email = result.company_email;
                profileMod.company_industry = result.company_industry;
                profileMod.company_name = result.company_name;
                profileMod.company_size = result.company_size;
                setProfileModel(profileMod)
                tokenService.setProfile(profileMod);
                tokenService.setGuest(false);
                getCredit(profileMod);
            }
        }).catch((err) => {
            if (err.response.status == 400) {
                const profileMod: ProfileSCModel = profileModels;
                setProfileModel(profileMod)
                console.log(profileMod);
                tokenService.setProfile(profileMod);
                handleNavigation(navigate, '/register', utmSource, utmMedium, feature, version);
            }
        })
    }

    const getCredit = (profileModels) => {


        GPTService.getCredit().then((res) => {
            setCreditModel(res.data);
            if (redirect) {
                handleNavigation(navigate, redirect, utmSource, utmMedium, feature);
            } else {
                handleNavigation(navigate, '/dashboard', utmSource, utmMedium, feature);
            }
        })
    }
    return (

        <>
            <div className="check-login">
                <div className="check-login-control">
                    <Lottie animationData={SearchingAnimation} loop={true} className="check-login-loading" />
                    <span className="check-login-text">Loading . . .</span>
                </div>
            </div>
        </>
    )
}

export default CheckLoginComponent;