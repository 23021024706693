export const EXPERIENCE_LIST = [
    {
        name: "No Experience", value: "0", status: false
    },
    {
        name: "1-2 years", value: "1-2", status: false
    },
    {
        name: "2-4 years", value: "2-4", status: false
    },
    {
        name: "5+ years", value: "5+", status: false
    },
]