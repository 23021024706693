import { useContext, useEffect, useState } from "react";
import RegisterModel from "../../model/register/register.model";
import "./register.scss";
import templateConfig from "../../template-config";
import languageConfig from "../../language-config";
import { Button, Form, FormCheck } from "react-bootstrap";
import INDUSTRY_JSON from "../../assets/json/industry.json";
import { Checkbox } from "@mui/material";
import { ProviderContext } from "../../context/provider.context";
import ProfileSCModel from "../../model/profile-sc.model";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import { useLocation, useNavigate } from "react-router-dom";
import tokenService from "../../services/token.service";
import UtilityService from "../../utils/utilities";
import GPTService from "../../services/gpt.service";

const RegisterComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [registerModel, setRegisterModel] = useState<RegisterModel>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [validationEmail, setValidationEmail] = useState(false);
    const { profileModel }: any = useContext(ProviderContext)
    const [profileMod, setProfileMod] = useState<ProfileSCModel>();
    const { utmSource, utmMedium, version, feature } = getUTMParams(location);
    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
            if (!registerModel) {
                console.log(profileModel);
                if (profileModel && profileModel.email) {
                    const registerModelInit: RegisterModel = {
                        company_email: profileModel.email,
                        company_industry: "",
                        company_name: "",
                        company_size: "",
                        user_uuid: ""
                    }
                    console.log(registerModelInit);
                    setRegisterModel(registerModelInit)
                } else {
                    const registerModelInit: RegisterModel = {
                        company_email: "",
                        company_industry: "",
                        company_name: "",
                        company_size: "",
                        user_uuid: ""
                    }
                    setRegisterModel(registerModelInit)
                }
            }
        } else {
            let redirectPath = location.pathname;


            handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, version, redirectPath);
        }
    }, [profileModel])



    const onTextChange = (action, value) => {

        if (registerModel) {
            const registerMod = { ...registerModel }
            if (action == 'email') {
                console.log(validateEmail(value));
                if (validateEmail(value)) {
                    registerMod.company_email = value
                    setValidationEmail(false);
                } else {
                    registerMod.company_email = value
                    setValidationEmail(true);
                }
            }
            if (action == 'company_name') {
                registerMod.company_name = value;
            }

            setRegisterModel(registerMod)
        }

    }

    const onSelectChange = (action, value) => {
        if (registerModel) {
            const registerMod = { ...registerModel }
            if (action == 'industry') {
                if (value == "888") {
                    registerMod.company_industry = ''
                } else {
                    registerMod.company_industry = value;
                }
            }
            if (action == 'company_size') {
                if (value == "888") {
                    registerMod.company_size = ''
                } else {
                    registerMod.company_size = value;
                }

            }
            setRegisterModel(registerMod)
        }
    }
    const validateEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const submit = () => {
        if (registerModel) {
            setIsSubmit(true);

            if (profileMod) {
                registerModel.user_uuid = profileMod.uuid;
                console.log(registerModel);

                if (registerModel.company_email
                    && !validationEmail
                    && registerModel.company_industry
                    && registerModel.company_name
                    && registerModel.company_size && registerModel.user_uuid) {
                    GPTService.createCompanyProfile(registerModel, profileMod.uuid).then((res) => {
                        if (res) {
                            const result = res.data as RegisterModel;
                            const profileMods: ProfileSCModel = profileMod;
                            profileMods.company_name = result.company_name;
                            profileMods.company_industry = result.company_industry;
                            profileMods.company_email = result.company_email;
                            profileMods.company_size = result.company_size;
                            tokenService.setProfile(profileMods);
                            setProfileMod(profileMods)
                            UtilityService().clickSendEvent("register_successfull", "click");
                            if (profileMods.isGuest) {
                                handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, version);
                            } else {
                                handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, version);
                            }

                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            }
        }
    }
    return (

        <>
            <div className="register-main">
                {
                    registerModel &&
                    <div className="register-main-control">


                        <img src={templateConfig.assets_env.main_logo_active} className="register-logo"></img>
                        <span className="register-title">{languageConfig.register_page.title}</span>

                        <div className="register-grid-form">

                            <div className="register-item-control">
                                <Form.Label htmlFor="position" className="register-item-label">{languageConfig.register_page.company_name}</Form.Label>

                                <Form.Control type="text" value={registerModel.company_name}
                                    className="register-item-input-form" onChange={(e) => onTextChange('company_name', e.target.value)}></Form.Control>
                                {
                                    (isSubmit && !registerModel.company_name) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.input_error}
                                    </span>
                                }

                            </div>
                            <div className="register-item-control">
                                <Form.Label htmlFor="position" className="register-item-label">{languageConfig.register_page.industry}</Form.Label>
                                <Form.Select className="register-item-input-form" onChange={(e) => onSelectChange('industry', e.target.value)} >
                                    <option value="888" selected>เลือกประเภทบริษัท</option>
                                    {
                                        INDUSTRY_JSON.map((item: any, index: number) => {

                                            return (
                                                <option value={item.nameTh} key={index}>{item.nameTh}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {
                                    (isSubmit && !registerModel.company_industry) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.select_error}
                                    </span>
                                }

                            </div>
                            <div className="register-item-control">
                                <Form.Label htmlFor="position" className="register-item-label">{languageConfig.register_page.company_size}</Form.Label>
                                <Form.Select className="register-item-input-form" onChange={(e) => onSelectChange('company_size', e.target.value)} >
                                    <option value="888" selected>เลือกขนาดบริษัท</option>
                                    <option value="1-10 คน">1-10 คน</option>
                                    <option value="11-50 คน">11-50 คน</option>
                                    <option value="51-200 คน">51-200 คน</option>
                                    <option value="201-500 คน">201-500 คน</option>
                                    <option value="501-1,000 คน">501-1,000 คน</option>
                                    <option value="มากกว่า 1,000 คน">มากกว่า 1,000 คน</option>

                                </Form.Select>
                                {
                                    (isSubmit && !registerModel.company_size) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.select_error}
                                    </span>
                                }

                            </div>
                            <div className="register-item-control">
                                <Form.Label htmlFor="position" className="register-item-label">{languageConfig.register_page.email_company}</Form.Label>

                                <Form.Control type="text" className="register-item-input-form"
                                    value={registerModel.company_email}
                                    onChange={(e) => onTextChange('email', e.target.value)}></Form.Control>
                                {
                                    (isSubmit && !registerModel.company_email && !validationEmail) ?

                                        <span className="error-text-size">
                                            {languageConfig.error.input_error}
                                        </span> : (isSubmit && validationEmail) && <span className="error-text-size">
                                            {languageConfig.error.email_validate}
                                        </span>
                                }

                            </div>


                        </div>
                        <div className="d-flex flex-column">
                            <span className="register-terms-title">การสมัครเพื่อใช้ ระบบจะถือว่าท่านได้เข้าใจและยินยอม <a href="https://scoutout.net/terms-th/" className="register-terms-link">ข้อกำหนด/เงื่อนไขการใช้งาน</a> และ <a href="https://scoutout.net/privacy-th/" className="register-terms-link">นโยบายความเป็นส่วนตัว</a></span>
                            <Button onClick={submit} className="register-button-submit">{languageConfig.register_page.submit}</Button>

                            <a href="/" className="register-terms-link text-center">กลับหน้าหลัก</a>
                        </div>
                    </div>
                }
            </div>


        </>
    )
}

export default RegisterComponent;