import { useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import { useContext, useEffect, useState } from "react";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import ProfileSCModel from "../../model/profile-sc.model";
import { AppBar, Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import templateConfig from "../../template-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faCoins } from "@fortawesome/free-solid-svg-icons";
import tokenService from "../../services/token.service";
import { ProviderContext } from "../../context/provider.context";
import CreditModel from "../../model/credit.model";
import languageConfig from "../../language-config";
import CreditUsageModal from "../../modal/credit-usage.modal";
import config from "../../config";
import UtilityService from "../../utils/utilities";
import GAConfig from "../../ga-config";
import TopupModal from "../../modal/top-up.modal";
const HeaderComponent = ({ profileModel, clickHideDrawer, clickMenu }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { utmSource, utmMedium, version, feature } = getUTMParams(location);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const drawerWidth = 240;

    const { creditModel }: any = useContext(ProviderContext)
    const [creditMod, setCreditMod] = useState<CreditModel>(creditModel);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openCreditUsageModal, setOpenCreditUsageModal] = useState(false);
    const open = Boolean(anchorEl);
    const [showTopup, setShowTopup] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        if (profileModel) {
            console.log(profileModel);
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        if (creditModel) {
            console.log("Updating...", creditModel);
            setCreditMod(creditModel)
        }
    }, [creditModel])

    const logout = () => {

        localStorage.clear();
        navigate('/')
    }

    const handleCloseCreditUsageModal = () => {


        UtilityService().clickSendEvent("open_credit_history", "click");
        setOpenCreditUsageModal(false);
    }

    const clickHr9 = () => {
        UtilityService().clickSendEvent("open_hr9_ats", "click");
        window.open(config.app.hr9_services);
    }

    const clickLogo = () => {

        navigate("/");
    }
    const handleCloseShowTopup = () => {
        setShowTopup(false);
    }

    return (

        <div className="header-control">
            {
                profileMod &&
                <>
                    <AppBar className="header-app-bar" position="fixed"
                    
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, minHeight: '41px' }}>
                        <Toolbar>
                            <img src={templateConfig.assets_env.main_logo} onClick={clickLogo} className="d-none d-md-block header-app-bar-logo-company"></img>
                            <FontAwesomeIcon icon={faBars} className="d-block d-md-none header-app-bar-logo-company" onClick={clickHideDrawer} />
                        </Toolbar>

                        <div className="header-app-right-menu">
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                {
                                    creditMod &&
                                    <div className="header-credit-control" onClick={creditMod.credit > 0 ? () => clickMenu(41) : () => setShowTopup(true)}>
                                        <FontAwesomeIcon className="header-credit-coin" icon={faCoins} beat />
                                        <span className="header-credit-text">
                                            {creditMod.credit > 0 ? creditMod.credit + " " + languageConfig.header.credits : languageConfig.header.no_credits} </span>
                                    </div>
                                }

                                {/* <FontAwesomeIcon  icon={faBell} /> */}
                                {
                                    profileMod.email && 
                                    <Tooltip title={profileMod.email}>
                                    <div className="avatar-control"     onClick={handleClick}>
                                        <IconButton
                                        
                                          
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >

                                            <Avatar sx={{ width: 32, height: 32 }} className="avatar-icon">

                                                {profileMod.email.substring(0, 2)[0].toLocaleUpperCase()}

                                            </Avatar>

                                        </IconButton>
                                        <FontAwesomeIcon icon={faBars} className="avatar-menu"></FontAwesomeIcon>
                                    </div>
                                </Tooltip>
                                }
                                
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => clickMenu(88)}>
                                    <span className="header-menu-text">Profile</span>
                                </MenuItem>
                                {
                                    config.app_name != "TALENTSAUCE" && <MenuItem onClick={() => clickHr9()}>
                                        <span className="header-menu-text">ไปยัง HR9</span>
                                    </MenuItem>
                                }

                                <MenuItem onClick={() => setOpenCreditUsageModal(true)}>
                                    <span className="header-menu-text"> เกี่ยวกับการใช้เครดิต</span>
                                </MenuItem>
                                {/* <MenuItem onClick={handleClose}>
                                    <Avatar /> My account
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Add another account
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem> */}
                                <MenuItem onClick={logout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    <span className="header-menu-text"> Logout </span>
                                </MenuItem>
                            </Menu>
                        </div>
                    </AppBar>

                </>
            }
            {
                showTopup &&
                <TopupModal open={showTopup} handleClose={handleCloseShowTopup}></TopupModal>
            }

            {
                openCreditUsageModal &&
                <CreditUsageModal open={openCreditUsageModal} handleClose={handleCloseCreditUsageModal}></CreditUsageModal>
            }
        </div>
    )
}

export default HeaderComponent;