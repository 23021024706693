

const techsauce = {

    menu:{
        home: true,
        profile: true,
        talent_acquisition: {
            main: true,
            talent_search: true,
            jd_generator: true,
            jd_checker: true,
            job_post: true,
            post_hr9: false,
        },
        manage_candidates:{
            main: true,
            resume_prescreen: true,
            interview_question: true,
            salary_offering: true,
            ats_hr9: false
        },
        company_branding:{
            main: false,
            crm: false,
            career_site: false
        }
    }
}


const normal = {

    menu:{
        home: true,
        profile: true,
        talent_acquisition: {
            main: true,
            talent_search: true,
            jd_generator: true,
            jd_checker: true,
            job_post: true,
            post_hr9: false,
        },
        manage_candidates:{
            main: true,
            resume_prescreen: true,
            interview_question: true,
            salary_offering: true,
            ats_hr9: true
        },
        company_branding:{
            main: true,
            crm: false,
            career_site: true
        }
    }
}

const normal_prod = {

    menu:{
        home: true,
        profile: true,
        talent_acquisition: {
            main: true,
            talent_search: true,
            jd_generator: true,
            jd_checker: true,
            job_post: true,
            post_hr9: false,
        },
        manage_candidates:{
            main: true,
            resume_prescreen: true,
            interview_question: true,
            salary_offering: true,
            ats_hr9: false
        },
        company_branding:{
            main: false,
            crm: false,
            career_site: false
        }
    }
}


const menuConfig = 
process.env.REACT_APP_ENVIRONMENT === 'production' ? normal_prod : 
process.env.REACT_APP_ENVIRONMENT === 'local' ? normal :
process.env.REACT_APP_ENVIRONMENT === 'talentsauce_test' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'talentsauce' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'talentsauce_local' ? techsauce:
process.env.REACT_APP_ENVIRONMENT === 'dev' ? normal
    : normal;

export default {
    // Add common config values here
    ...menuConfig
};