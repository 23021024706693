import { useEffect, useState } from "react";
import "./history-credit.scss";
import ProfileSCModel from "../../model/profile-sc.model";
import CreditModel, { History } from "../../model/credit.model";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../utils/theme";
import languageConfig from "../../language-config";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { LogResultModel } from "../../model/log-result.model";
import ResultModal from "../../modal/result.modal";
import ShareJobComponent from "../features/post-job-social/share/share-job";
import InputJobPostModel from "../../model/job-post/input-job-post.model";
import UtilityService from "../../utils/utilities";
import moment from "moment";
import GPTService from "../../services/gpt.service";



const HistoryCreditComponent = ({ profileModel }: any) => {

    const [creditMod, setCreditMod] = useState<CreditModel>();
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [openResult, setOpenResult] = useState(false);
    const [result, setResult] = useState<LogResultModel>();
    const [inputJobPost, setInputJobPost] = useState<InputJobPostModel>();
    const [isShowModalShareImg, setIsShowModalShareImg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
            getCredit(profileModel);
            UtilityService().clickSendEvent("open_credit_history", "load");
        }
    }, [profileModel])

    const getCredit = (profileModels) => {

        setIsLoading(true);
        GPTService.getCredit().then((res) => {
    
            if(res){
                setCreditMod(res.data)
            }
        })
    }
   
    const handleCloseShowShareImg = () => {

        setIsShowModalShareImg(false);
    }
    const setShowShare = (inputJobPostMod) => {
        setInputJobPost(inputJobPostMod)
        console.log("Share Img")
        setIsShowModalShareImg(true);
        setOpenResult(false);

    }
    const clickViewResponse = (log_uuid, type) => {

        GPTService.getLogResponse(log_uuid).then((res) => {

            if (res) {
                res.data.type = type;
                setResult(res.data);
                setOpenResult(true);
            }

        })
    }
    const convertGMT = (dateString) => {
        // Create a Date object from the timestamp

        
        // Create a Date object from the timestamp
        const date = new Date(dateString);

        // Add 7 hours to the date (1 hour = 60 minutes = 3600 seconds = 3600000 milliseconds)
        const dateInGMT7 = new Date(date.getTime() + (7 * 3600000));
        const formatDateString = moment(dateInGMT7).format('DD/MM/YYYY HH:mm');
        return formatDateString;
    }
    return (

        <>

            <div className="history-main">

                <div className="history-main-control">
                    {
                        (creditMod && creditMod.histories &&  creditMod.histories.length > 0) ?
                            <TableContainer component={Paper}>
                                <Table className="history-main-table-control" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='10%'>{languageConfig.history.order}</StyledTableCell>
                                            <StyledTableCell align="center" width='20%'>{languageConfig.history.features}</StyledTableCell>
                                            <StyledTableCell align="center" width='20%'>{languageConfig.history.created_date}</StyledTableCell>
                                            <StyledTableCell align="center" width='20%'>{languageConfig.history.credit_used}</StyledTableCell>
                                            <StyledTableCell align="center" width='10%'>{languageConfig.history.view_more}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            creditMod.histories.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at))).map((row: History, index: number) => (
                                                <StyledTableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell align="center" className="d-none d-md-table-cell" >
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">{row.type}</StyledTableCell>
                                                    <StyledTableCell align="center">{convertGMT(row.created_at)}</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {row.credit}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Button className="history-button-view" 
                                                        onClick={() => clickViewResponse(row.log_uuid, row.type)}>{languageConfig.history.view_more}</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))


                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer> :
                            isLoading ?

                                <div className="history-main-loading-control">
                                    <div className="history-main-loading-progress-control">
                                        <CircularProgress className="history-main-loading-progress"></CircularProgress>
                                        <span className="history-main-loading-text">กำลังโหลด</span>
                                    </div>
                                </div> :
                                <>

                                    <div className="history-no-result">

                                        <div className="history-no-result-control">
                                            <FontAwesomeIcon icon={faSearch} className="history-no-result-icon"></FontAwesomeIcon>
                                            <span className="history-no-result-text">{languageConfig.history.no_result}</span>
                                        </div>
                                    </div>
                                </>
                    }


                </div>
            </div>
            <ResultModal open={openResult} setOpen={setOpenResult} result={result} setShowShare={setShowShare}></ResultModal>

            {
                <ShareJobComponent isOpen={isShowModalShareImg} handleClose={handleCloseShowShareImg} inputMod={inputJobPost}></ShareJobComponent>
            }
        </>
    )
}

export default HistoryCreditComponent;