import { Modal, ModalBody, ModalHeader } from "react-bootstrap"
import HistoryCandidateComponent from "../components/history-credit/history-candidate/history-candidate"
import { useEffect } from "react"
import "./candidate-history.modal.scss";

const CandidateHistoryModal = ({ open, handleClose }: any) => {

    useEffect(() => {


    }, [])


    return (
        <>
            <div className="candidate-history-modal-main">
                <Modal show={open} onHide={handleClose} className="candidate-history-modal-main">
                    <ModalHeader closeButton>

                    </ModalHeader>
                    <ModalBody>
                        <HistoryCandidateComponent ></HistoryCandidateComponent>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}
export default CandidateHistoryModal;