import { Button } from "react-bootstrap";
import languageConfig from "../../language-config";
import templateConfig from "../../template-config";
import "./login.scss";
import AuthenMysa from "../../services/authen-mysa";
import { useLocation, useNavigate } from "react-router-dom";
import { getUTMParams } from "../../services/handler-route.service";
import HeaderComponent from "../header/header";
import config from "../../config";
import UtilityService from "../../utils/utilities";



const LoginComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { utmSource, utmMedium, version, feature,redirect } = getUTMParams(location);
    const loginButton = () => {
        UtilityService().clickSendEvent("login", "click");
        AuthenMysa().authenticateUrl(utmSource, utmMedium, feature);
    }
    const registerButton = () => {
        UtilityService().clickSendEvent("register", "click");
        AuthenMysa().authenticateUrlRegister(utmSource, utmMedium, feature);
    }

    const homeLogo = () => {
        UtilityService().clickSendEvent("home", "click");
        navigate("/")
    }
    return (

        <div className="login-control">
            <div className="login-main-container">
                <img src={templateConfig.assets_env.main_logo_active} onClick={homeLogo} className="login-main-logo-img"></img>
                <span className="login-main-logo-title-text">

                    {languageConfig.login_page.title}
                </span>

                <div className="login-main-button-control">
                    <Button className="login-main-button-register" onClick={registerButton}>    {languageConfig.login_page.register_button}</Button>
                    <Button className="login-main-button-login" onClick={loginButton}>    {languageConfig.login_page.login_button}</Button>
                </div>

                
            </div>
            {/* <div className="login-main-footer-control">
                        <img src={templateConfig.assets_env.power_logo_gray} className="login-main-footer-img-logo"></img>
                        <span className="login-main-footer-text">{languageConfig.login_page.footer_text}</span>
                </div> */}
        </div>
    )
}

export default LoginComponent;