import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';
import LoginComponent from './components/login/login';
import { ProviderContext } from './context/provider.context';
import ProfileSCModel from './model/profile-sc.model';
import CreditModel from './model/credit.model';
import CheckLoginComponent from './components/check-login/check-login';
import DashboardComponent from './components/dashboard/dashboard';
import RegisterComponent from './components/register/register';
import apiConfig from './api-config';
import ShareJobComponent from './components/features/post-job-social/share/share-job';
import PostJobComponent from './components/features/post-job-social/apply-job/post-job';
import TalentSauceLandingComponent from './components/landing/talent-sauce/talent-sauce-landing';
import TalentSaucePricingComponent from './components/landing/talent-sauce/pricing/talent-sauce-pricing';
import PrivacyComponent from './components/landing/privacy/privacy';
import TermsComponent from './components/landing/terms/terms';
import TalentSearchPreviewComponent from './components/talent-search/preview/talent-search-preview';



const AppTs = () => {
    const [codeVerifier, setCodeVerifier] = useState();
    const [resultRawString, setResultRawString] = useState("");
    const [profileModel, setProfileModel] = useState<ProfileSCModel>();
    const [menuActive, setMenuActive] = useState(1);
    const [menuLeftActive, setMenuLeftActive] = useState(0);
    const [isGuest, setIsGuest] = useState(false);
    const [creditModel, setCreditModel] = useState<CreditModel>();

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', '#F26622');
        document.documentElement.style.setProperty('--primary-blue-color', '#2C3694');
        document.documentElement.style.setProperty('--hover-primary-color', '#EF4123');
        document.documentElement.style.setProperty('--secondary-color', '#FCB918');
        document.documentElement.style.setProperty('--text-black-color', '#232323');
        document.documentElement.style.setProperty('--text-button-color', '#ffffff');
        document.documentElement.style.setProperty('--text-button-secondary-color', '#F26622');
        document.documentElement.style.setProperty('--text-white-color', '#ffffff');
        document.documentElement.style.setProperty('--text-gray-color', '#888888');
        document.documentElement.style.setProperty('--text-primary-soft-color', '#FF9663');
        document.documentElement.style.setProperty('--pro-color', '#f2c319');
        document.documentElement.style.setProperty('--brown-color', '#393031');
        document.documentElement.style.setProperty('--link-color', '#64b5fc');
        document.documentElement.style.setProperty('--text-blue-color', '#2C3694');
        document.documentElement.style.setProperty('--border-line', '#3b3b3b');
        document.documentElement.style.setProperty('--border-input', '#888888');
        document.documentElement.style.setProperty('--button-primary', '#F26622');
        document.documentElement.style.setProperty('--button-secondary', '#ffffff');
        document.documentElement.style.setProperty('--error-color', '#ef4123');
        document.documentElement.style.setProperty('--bg-color', 'white');
    }, [])
    return (
        <div className="App">
            <BrowserRouter>
                <ProviderContext.Provider
                    value={{
                        codeVerifier, setCodeVerifier,
                        profileModel, setProfileModel,
                        menuActive, setMenuActive,
                        resultRawString, setResultRawString,
                        creditModel, setCreditModel,
                        isGuest, setIsGuest,
                        menuLeftActive, setMenuLeftActive
                    }}
                >
                    <Routes>

                        <Route path="/" element={<TalentSauceLandingComponent />} />
                        <Route path="/privacy" element={<PrivacyComponent />} />
                        <Route path="/terms" element={<TermsComponent />} />
                        <Route path="/pricing" element={<TalentSaucePricingComponent />} />
                        <Route path="/login" element={<LoginComponent />} />
                        <Route path="/register" element={<RegisterComponent />} />
                        <Route path="/preview" element={<TalentSearchPreviewComponent />} />
                        <Route path="/checking" element={<CheckLoginComponent />} />
                        <Route path="/dashboard" element={<DashboardComponent />} />
                        <Route path='/share-job' element={<ShareJobComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.profile} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.jd_generator} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.interview_questions_generator} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.jd_checker} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.resume_prescreen} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.salary_offering} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.post_job_social} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.talent_search} element={<DashboardComponent />} />
                        <Route path={'/dashboard' + apiConfig.menu.history_credit} element={<DashboardComponent />} />
                        <Route path="/job/:id" element={<PostJobComponent />} />
                    </Routes>
                </ProviderContext.Provider>
            </BrowserRouter>
            <CookieConsent
                enableDeclineButton
                declineButtonText="Accept Necessary"
                location="bottom"
                buttonText="Accept All"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                declineButtonStyle={{ fontSize: "13px", borderRadius: "12px" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px" }}
                expires={150}
            >
                <Container>
                    Our website uses cookies to provide you with an enhanced experience and to offer content, services, and advertisements that you may be interested in. Click for more information at  <a href="https://talentsauce.co/terms">Terms of Service</a>
                    and <a href="https://talentsauce.co/privacy/">Privacy Policy</a>
                </Container>

            </CookieConsent>

        </div>
    );
}

export default AppTs;
