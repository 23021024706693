import { Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { CampaignModel, Variant } from "../../../../model/campaign/campaign.model";
import "./create-campaign.scss";
import AudienceListModel, { Audience } from "../../../../model/audience/audience-list.model";
import CRMServices from "../../../../services/crm.service";
import ProfileSCModel from "../../../../model/profile-sc.model";
import PREVIEW_JOBCARD_IMG from "../../../../assets/images/campaign-preview-jobcard.png";
import { InsightFollowerModel } from "../../../../model/campaign/insight-follower.model";



const CreateCampaignComponent = ({ isOpen, handleClose, isEdit, id, profileModel }: any) => {

    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [isEditMod, setIsEdit] = useState(isEdit);
    const [isLoading, setIsLoading] = useState(false);
    const [campaignObject, setCampaignObject] = useState<CampaignModel>();
    const [audienceList, setAudienceList] = useState<AudienceListModel>();
    const [audienceListSelect, setAudienceListSelect] = useState<AudienceListModel>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [targetName, setTargetName] = useState("allTarget");
    const [isABTesting, setIsABTesting] = useState(false);
    const [variant1, setVariant1] = useState("");
    const [variant2, setVariant2] = useState("");
    const [variant1Type, setVariant1Type] = useState("default")
    const [variant2Type, setVariant2Type] = useState("default")
    const [variant1ImageUrl, setVariant1ImageUrl] = useState("")
    const [variant1ClickUrl, setVariant1ClickUrl] = useState("")
    const [variant2ImageUrl, setVariant2ImageUrl] = useState("")
    const [variant2ClickUrl, setVariant2ClickUrl] = useState("")
    const [insightModel, setInsightModel] = useState<InsightFollowerModel>();
    const [totalVolume, setTotalVolume] = useState(0);
    const [totalResult, setTotalResult] = useState(0);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    useEffect(() => {
        if (profileMod) {
            getAudienceList();
        }
    }, [profileMod])

    useEffect(() => {
        if (!campaignObject && !isEdit) {
            const campaignMod: CampaignModel = {
                audience_uuids: [],
                broadcast_name: "",
                target_sample_percent: 100,
                variants: [] as Variant[],
                audience_approx_count: 0
            }
            setCampaignObject(campaignMod);
        }
    }, [campaignObject])

    const getTotalVolume = () => {
        CRMServices().getInsightReachVolume().then((res) => {

            console.log(res);
            if (res) {
                const result = res as InsightFollowerModel;
                setTotalVolume(result.targetedReaches);
                setTotalResult(result.targetedReaches);
                setInsightModel(result);
            }
        })
    }
    const getAudienceList = () => {
        if (profileMod) {
            CRMServices().getAudienceAll().then((res: AudienceListModel) => {
                console.log(res);
                if (res.audiences) {
                    res.audiences.map((item: Audience, index: number) => {
                        res.audiences[index].status = false;
                    })
                    getTotalVolume();
                    setAudienceList(res)
                    setAudienceListSelect(res);
                }

            })
        }
    }

    const onInputChange = (action, value) => {
        if (campaignObject) {
            const campaignObjMod = { ...campaignObject }
            if (action == 'name') {
                campaignObjMod.broadcast_name = value
            }
            if (action == 'targetSample') {
                campaignObjMod.target_sample_percent = value
                calculateTotalVolumeWithTargetSample(value);
            }

            if (action == 'variant1') {
                setVariant1(value);
            }

            if (action == 'variant2') {
                setVariant2(value);
            }

            if (action == 'variant2ClickUrl') {
                setVariant2ClickUrl(value);
            }
            if (action == 'variant2ImageUrl') {
                setVariant2ImageUrl(value);
            }
            setCampaignObject(campaignObjMod)
        }
    }

    const handleChangeValueSelect = (action, event) => {

        if (action == 'audiencelist') {
            console.log(event);
            if (audienceListSelect) {
                const audienceUpdate = { ...audienceListSelect }
                let totalPlus = 0;
                audienceListSelect.audiences.map((item: Audience, index: number) => {
                    if (event.target.value == item.audience_uuid) {
                        if (audienceUpdate.audiences[index].status) {
                            audienceUpdate.audiences[index].status = false
                        } else {

                            audienceUpdate.audiences[index].status = true
                        }
                    }
                })

                audienceUpdate.audiences.map((item: Audience, index: number) => {

                    if (item.status) {
                        totalPlus += item.audience_approx_count;
                    }
                })

                setTotalVolume(totalPlus)
                setAudienceListSelect(audienceUpdate);
            }
        } else if (action == 'variant1') {
            setVariant1Type(event.target.value);
        } else if (action == 'variant2') {
            setVariant2Type(event.target.value);
        }
    }

    const calculateTotalVolumeWithTargetSample = (value) => {

        if (audienceListSelect) {
            let totalPlus = 0;
            audienceListSelect.audiences.map((item: Audience, index: number) => {

                if (item.status) {
                    totalPlus += item.audience_approx_count;
                }
            })

            const finalApprox = (totalPlus * value) / 100;

            if (Number(Number(finalApprox).toPrecision(1)) < 1) {
                setTotalVolume(1);
            } else {
                setTotalVolume(Number(Number(finalApprox).toPrecision(1)))
            }
        }
    }


    const onCheck = (action, index, value) => {
        if (campaignObject) {
            const campaignMod = { ...campaignObject }
            if (action == 'variantsCheck') {
                setIsABTesting(value);

                if (!value) {
                    setVariant2("")
                }
            }
        }
    }



    const removeTag = (id) => {
        if (audienceListSelect) {
            const audienceUpdate = { ...audienceListSelect }
            let totalPlus = 0;

            audienceListSelect.audiences.map((item: Audience, index: number) => {
                if (id == item.audience_uuid) {
                    if (audienceUpdate.audiences[index].status) {
                        audienceUpdate.audiences[index].status = false
                    } else {
                        audienceUpdate.audiences[index].status = true
                    }
                }
            })
            audienceUpdate.audiences.map((item: Audience, index: number) => {

                if (item.status) {
                    totalPlus += item.audience_approx_count;
                }
            })

            setTotalVolume(totalPlus)
            setAudienceListSelect(audienceUpdate);
        }
    }


    const submit = () => {

        if (campaignObject) {
            setIsSubmit(true);
            let campaignObjectRes: CampaignModel = {} as CampaignModel;
            if (variant1) {
                campaignObjectRes.variants = []
                if (variant1Type == "IMAGE") {
                    const variant1Obj: Variant = {
                        name: variant1,
                        type: variant1Type,
                        click_url: variant1ClickUrl,
                        image_url: variant1ImageUrl
                    }
                    campaignObjectRes.variants.push(variant1Obj)
                } else {
                    const variant1Obj: Variant = {
                        name: variant1,
                        type: variant1Type
                    }
                    campaignObjectRes.variants.push(variant1Obj)
                }


            }

            if (isABTesting) {
                if (variant2) {
                    if (variant2Type == "IMAGE") {
                        const variant2Obj: Variant = {
                            name: variant2,
                            type: variant2Type,
                            click_url: variant2ClickUrl,
                            image_url: variant2ImageUrl
                        }
                        campaignObjectRes.variants.push(variant2Obj)
                    } else {
                        const variant2Obj: Variant = {
                            name: variant2,
                            type: variant2Type
                        }
                        campaignObjectRes.variants.push(variant2Obj)
                    }

                }
            }


            if (targetName == "allTarget") {
                campaignObjectRes.broadcast_name = campaignObject.broadcast_name;
                campaignObjectRes.target_sample_percent =  Number(campaignObject.target_sample_percent);
            } else {
                if(audienceListSelect){
                    let arrayValue = [] as string[];
                    audienceListSelect.audiences.map((item: Audience, index:number) => {
                        if(item.status){
                            arrayValue.push(item.audience_uuid);
                        }
                    })
                    campaignObjectRes.audience_uuids = arrayValue;
                }
               
                
                campaignObjectRes.broadcast_name = campaignObject.broadcast_name;
                campaignObjectRes.target_sample_percent = Number(campaignObject.target_sample_percent);
            }
            console.log(targetName);
            campaignObjectRes.audience_approx_count = totalVolume;
            console.log(campaignObjectRes);
            CRMServices().createCampaign(campaignObjectRes).then((res) => {

                console.log(res);
                if(res.name != "AxiosError"){
                    handleClose();
                }
              
            })
        }
    }

    const onRadioCheck = (action, value) => {
        if (action == 'targetGroup') {
            setTargetName(value);
            if (value == "allTarget") {
                setTotalVolume(totalResult);

                if (audienceListSelect) {
                    const audienceUpdate = audienceListSelect;
                    audienceListSelect.audiences.map((item: Audience, index: number) => {
                        audienceUpdate.audiences[index].status = false
                    })
                    setAudienceListSelect(audienceUpdate)
                }
            }
        }
    }

    return (

        <>
            <div className="campaign-create-modal">
                <Modal show={isOpen} onHide={handleClose} className="campaign-create-modal" backdrop={'static'} >
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMod ? 'แก้ไข' : 'สร้าง'} Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {
                            campaignObject && insightModel && !isLoading ?
                                <div className="create-campaign-control">
                                    <div className="create-campaign-name-control">
                                        <span className="create-campaign-label" >ชื่อ Campaign</span>
                                        <FormControl className="create-audience-form-margin">

                                            <TextField id="standard-basic"

                                                value={campaignObject.broadcast_name} onChange={(e) => onInputChange('name', e.target.value)}
                                                variant="outlined" />
                                            {
                                                isSubmit && !campaignObject.broadcast_name &&
                                                <span className="create-audience-required-error">กรุณากรอกชื่อ</span>
                                            }
                                        </FormControl>
                                    </div>
                                    <span className="create-campaign-label">เลือก targeting</span>
                                    <div className="create-campaign-form-control-noborder">
                                        <FormControl className="create-campaign-form-margin">
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="allTarget"
                                                className="create-audience-radio-flex-row"
                                                value={targetName}
                                                onChange={(e) => onRadioCheck('targetGroup', e.target.value)}
                                            >
                                                <FormControlLabel className="create-audience-form-checkbox"
                                                    value="allTarget"
                                                    control={<Radio defaultChecked />}
                                                    label="All target" />
                                                <FormControlLabel className="create-audience-form-checkbox"
                                                    value="audienceTarget"
                                                    control={<Radio />}
                                                    label="เลือกตาม Audience" />

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {
                                        targetName == "audienceTarget" &&
                                        <>
                                            <span className="create-campaign-label">กลุ่ม Audience</span>
                                            <div className="create-campaign-form-control-noborder">
                                                <FormControl className="create-campaign-form-margin">


                                                    <Select
                                                        value="000"
                                                        placeholder="เลือก"
                                                        onChange={(e) => handleChangeValueSelect('audiencelist', e)}
                                                    >
                                                        <MenuItem value="000" selected>เลือก Audience</MenuItem>
                                                        {audienceListSelect && audienceListSelect.audiences.map((item: Audience, index: number) => (
                                                            <MenuItem value={item.audience_uuid}>
                                                                {
                                                                    item.status ?
                                                                        <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>
                                                                }  {item.audience_name}

                                                            </MenuItem>
                                                        ))}
                                                    </Select>


                                                    <div className="create-campaign-tag-control">
                                                        {
                                                            audienceListSelect && audienceListSelect.audiences.map((item: Audience, index: number) => {

                                                                return (
                                                                    item.status ?
                                                                        <span className="crete-campaign-tag-value" onClick={() => removeTag(item.audience_uuid)}>{item.audience_name} x</span> : null
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </>
                                    }



                                    <span className="create-campaign-label">Target sample (%)</span>
                                    <div className="create-campaign-form-control-noborder">
                                        <FormControl className="create-campaign-form-margin">
                                            <div className="d-flex">
                                                <TextField id="standard-basic"
                                                    disabled={targetName == "allTarget"}
                                                    value={campaignObject.target_sample_percent} onChange={(e) => onInputChange('targetSample', e.target.value)}
                                                    variant="outlined" type="number" /> <span className="create-campaign-label-last-flex">%</span>
                                            </div>

                                            {
                                                isSubmit && !campaignObject.target_sample_percent &&
                                                <span className="create-audience-required-error">กรุณากรอก%</span>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className="create-campaign-approx-volume-control">
                                        <span className="create-campaign-label">
                                            จำนวน Approx. Volume
                                            <span className="create-campaign-approx-volum-big">{totalVolume}</span>
                                        </span>
                                    </div>
                                    <span className="create-campaign-label">สร้าง Variant</span>
                                    <div className="create-campaign-form-control-noborder">
                                        <FormControl className="create-campaign-form-margin">
                                            <FormControlLabel control={<Checkbox
                                                defaultChecked={isABTesting}
                                                onChange={(e) => onCheck('variantsCheck', '0', e.target.checked)} value={isABTesting} />} label="AB Testing" />

                                        </FormControl>

                                        <div className="create-variant-control-grid">
                                            <div className="create-variant-control">
                                                <span className="create-campaign-label">Variant {isABTesting && '1'} name</span>
                                                <TextField id="standard-basic"
                                                    value={variant1} onChange={(e) => onInputChange('variant1', e.target.value)}
                                                    variant="outlined" />
                                                {
                                                    isSubmit && !variant1 &&
                                                    <span className="create-audience-required-error">กรุณากรอกชื่อ</span>
                                                }
                                                <span className="create-campaign-label">เลือกประเภท</span>
                                                <Select
                                                    value={variant1Type}
                                                    onChange={(e) => handleChangeValueSelect('variant1', e)}>
                                                    <MenuItem value="default" selected>เลือกประเภท</MenuItem>
                                                    <MenuItem value="JOB_CARD">Job card</MenuItem>
                                                    <MenuItem value="IMAGE">Image Card</MenuItem>
                                                </Select>
                                                {
                                                    variant1Type == "IMAGE" ?
                                                        <>
                                                            <span className="create-campaign-label">Image url</span>
                                                            <TextField id="standard-basic"
                                                                value={variant1ImageUrl} onChange={(e) => onInputChange('variant1ImageUrl', e.target.value)}
                                                                variant="outlined" />
                                                            {
                                                                isSubmit && !variant2ImageUrl &&
                                                                <span className="create-audience-required-error">กรุณากรอก url รุปภาพ</span>
                                                            }
                                                            <span className="create-campaign-label">Click url</span>
                                                            <TextField id="standard-basic"
                                                                value={variant1ClickUrl} onChange={(e) => onInputChange('variant1ClickUrl', e.target.value)}
                                                                variant="outlined" />
                                                            {
                                                                isSubmit && !variant2ClickUrl &&
                                                                <span className="create-audience-required-error">กรุณากรอก Click url</span>
                                                            }
                                                        </> : <>
                                                            <span className="create-campaign-label-example">ตัวอย่าง Recommend Job Card</span>
                                                            <img src={PREVIEW_JOBCARD_IMG} className="create-campaign-preview-img"></img>
                                                        </>
                                                }

                                            </div>
                                            {
                                                isABTesting &&
                                                <div className="create-variant-control">
                                                    <span className="create-campaign-label">Variant 2 name</span>
                                                    <TextField id="standard-basic"
                                                        value={variant2} onChange={(e) => onInputChange('variant2', e.target.value)}
                                                        variant="outlined" />
                                                    {
                                                        isSubmit && !variant2 &&
                                                        <span className="create-audience-required-error">กรุณากรอกชื่อ</span>
                                                    }
                                                    <span className="create-campaign-label">เลือกประเภท</span>
                                                    <Select
                                                        value={variant2Type}
                                                        onChange={(e) => handleChangeValueSelect('variant2', e)}>
                                                        <MenuItem value="default" selected>เลือกประเภท</MenuItem>
                                                        <MenuItem value="JOB_CARD">Job card</MenuItem>
                                                        <MenuItem value="IMAGE">Image Card</MenuItem>
                                                    </Select>

                                                    {
                                                        variant2Type == "IMAGE" ?
                                                            <>
                                                                <span className="create-campaign-label">Image url</span>
                                                                <TextField id="standard-basic"
                                                                    value={variant2ImageUrl} onChange={(e) => onInputChange('variant2ImageUrl', e.target.value)}
                                                                    variant="outlined" />
                                                                {
                                                                    isSubmit && !variant2ImageUrl &&
                                                                    <span className="create-audience-required-error">กรุณากรอก url รุปภาพ</span>
                                                                }
                                                                <span className="create-campaign-label">Click url</span>
                                                                <TextField id="standard-basic"
                                                                    value={variant2ClickUrl} onChange={(e) => onInputChange('variant2ClickUrl', e.target.value)}
                                                                    variant="outlined" />
                                                                {
                                                                    isSubmit && !variant2ClickUrl &&
                                                                    <span className="create-audience-required-error">กรุณากรอก Click url</span>
                                                                }
                                                            </> : <>
                                                                <span className="create-campaign-label-example">ตัวอย่าง Recommend Job Card</span>
                                                                <img src={PREVIEW_JOBCARD_IMG} className="create-campaign-preview-img"></img>
                                                            </>
                                                    }

                                                </div>

                                            }

                                        </div>
                                    </div>
                                    <div>
                                        <Button className="create-campaign-button-submit" onClick={submit}>{
                                            isEdit ? "Update" : "Submit"}</Button>
                                        {
                                            isEdit &&
                                            <Button className="create-campaign-button-delete">Delete</Button>
                                        }
                                    </div>
                                </div> :
                                <div className="create-campaign-loading-control">
                                    <div className="create-campaign-loading-progress-control">
                                        <CircularProgress className="create-campaign-loading-progress"></CircularProgress>
                                        <span className="create-campaign-loading-text">กำลังโหลด</span>
                                    </div>
                                </div>

                        }
                        {
                            isLoading &&
                            <div className="create-campaign-loading-control">
                                <div className="create-campaign-loading-progress-control">
                                    <CircularProgress className="create-campaign-loading-progress"></CircularProgress>
                                    <span className="create-campaign-loading-text">กำลังโหลด</span>
                                </div>
                            </div>

                        }
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default CreateCampaignComponent;