
import { useState } from "react";
import templateConfig from "../../../../template-config";
import "./template-0.scss";
import { CustomResumeModel } from "../../../../model/custom-resume/custom-resume-input.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarDays, faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { Education, Experience, Languages, Skill } from "../../../../model/custom-resume/candidate-profile.model";
const Template0Component = ({ customResumeModel }: any) => {

    const [customResume, setCustomResume] = useState<CustomResumeModel>(customResumeModel);



    return (

        <>
            <div className="template-0-main">
                <div className="custom-resume-a4">
                    <img src={customResume.logoUrl} className="company-logo-on-bg"></img>
                    {
                        customResume &&
                        <>
                            <img className="template-0-logo-comp" src={customResume.logoUrl} />

                            <div className="template-0-about-control">
                                <span className="template-0-name-text">{
                                    customResume.firstName + " " + customResume.lastName}</span>
                                {
                                    customResume.jobs.isShow &&
                                    <span className="template-0-position-text"
                                        style={{ color: customResume.textPrimaryColor }}>{customResume.jobs.result.name}</span>
                                }

                                {
                                    customResume.contact.isShow &&
                                    <div className="template-0-contact-control">

                                        <div className="template-0-contact-item">
                                            <FontAwesomeIcon icon={faEnvelope} className="template-0-contact-icon"
                                                style={{ color: customResume.textPrimaryColor }}></FontAwesomeIcon>
                                            <span className="template-0-contact-text" >
                                                {
                                                    customResume.contact.email
                                                }
                                            </span>
                                        </div>
                                        <div className="template-0-contact-item">
                                            <FontAwesomeIcon icon={faPhone} className="template-0-contact-icon"
                                                style={{ color: customResume.textPrimaryColor }}></FontAwesomeIcon>
                                            <span className="template-0-contact-text" >
                                                {
                                                    customResume.contact.phone
                                                }
                                            </span>
                                        </div>
                                        <div className="template-0-contact-item">
                                            <FontAwesomeIcon icon={faFacebook} className="template-0-contact-icon"
                                                style={{ color: customResume.textPrimaryColor }}></FontAwesomeIcon>
                                            <span className="template-0-contact-text" >
                                                {
                                                    customResume.contact.facebookId
                                                }
                                            </span>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="template-0-content-grid">
                                <div className="template-0-content-left">
                                    {
                                        customResume.experiences.isShow &&
                                        <div className="template-0-experience-control">
                                            <span className="template-0-title-section" style={{ color: customResume.textPrimaryColor }}>Experiences</span>
                                            <Divider className="template-0-divider" style={{ borderColor: customResume.textPrimaryColor }}></Divider>
                                            {

                                                customResume.experiences.result.map((item: Experience, index: number) => {

                                                    return (
                                                        <div className="template-0-experience-item" key={index}>
                                                            <span className="template-0-experience-job-title">
                                                                {item.jobTitle}
                                                            </span>
                                                            <div className="template-0-experience-date-control">
                                                                <FontAwesomeIcon icon={faCalendarDays} className="template-0-experience-date-icon"></FontAwesomeIcon>

                                                                <span className="template-0-experience-date-text">
                                                                    {
                                                                        item.stillWorkHere ?

                                                                            new Date(item.startDate).toDateString() + " - " + "Present" :
                                                                            new Date(item.startDate).toDateString() + " - " + (item.endDate && new Date(item.endDate).toDateString())
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="template-0-experience-location-control">
                                                                <FontAwesomeIcon icon={faLocationDot} className="template-0-experience-location-icon"></FontAwesomeIcon>

                                                                <span className="template-0-experience-location-text">
                                                                    {item.companyName}
                                                                </span>
                                                            </div>
                                                            <div className="template-0-experience-responsibility-control">
                                                                <span className="template-0-experience-responsibility-text">
                                                                    {item.responsibilities}
                                                                </span>
                                                            </div>
                                                            <Divider className="template-0-experience-item-divider"></Divider>
                                                        </div>

                                                    )
                                                })
                                            }
                                             {
                                        customResume.languages.isShow &&
                                        <div className="template-0-experience-control">
                                            <span className="template-0-title-section" style={{ color: customResume.textPrimaryColor }}>Languages</span>
                                            <Divider className="template-0-divider" style={{ borderColor: customResume.textPrimaryColor }}></Divider>
                                            {

                                                customResume.languages.result.map((item: Languages, index: number) => {

                                                    return (
                                                        <div className="template-0-experience-item" key={index}>
                                                            {
                                                                item.language == "en" ? "English":
                                                                item.language == "th" ? "Thai":
                                                                item.language == "jp" ? "Japanese":
                                                                item.language == "ch" && "Chinese"
                                                            }
                                                            <div className="template-0-language-grid">
                                                                <FormControlLabel control={<Checkbox disabled defaultChecked={item.listening} className="template-0-language-item-check" />} label="Listening" />
                                                                <FormControlLabel control={<Checkbox disabled defaultChecked={item.speaking} className="template-0-language-item-check" />} label="Speaking" />
                                                                <FormControlLabel control={<Checkbox disabled defaultChecked={item.writing} className="template-0-language-item-check" />} label="Writing" />
                                                                <FormControlLabel control={<Checkbox disabled defaultChecked={item.reading} className="template-0-language-item-check" />} label="Reading" />
                                                            </div>
                                                            <Divider className="template-0-experience-item-divider"></Divider>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </div>
                                    }

                                        </div>
                                    }
                                </div>
                                <div className="template-0-content-right">
                                    {
                                        customResume.cover_letter &&
                                        <>
                                            <span className="template-0-title-section" style={{ color: customResume.textPrimaryColor }}>Cover Letter</span>
                                            <Divider className="template-0-divider" style={{ borderColor: customResume.textPrimaryColor }}></Divider>
                                            <span className="template-0-cover-letter"
                                                style={{ borderColor: customResume.textPrimaryColor }}>`{customResume.cover_letter}`</span>
                                        </>

                                    }

                                    {
                                        customResume.educations.isShow &&
                                        <div className="template-0-experience-control">
                                            <span className="template-0-title-section" style={{ color: customResume.textPrimaryColor }}>Educations</span>
                                            <Divider className="template-0-divider" style={{ borderColor: customResume.textPrimaryColor }}></Divider>
                                            {

                                                customResume.educations.result.map((item: Education, index: number) => {

                                                    return (
                                                        <div className="template-0-experience-item" key={index}>
                                                            <span className="template-0-experience-school-title">
                                                                {item.educationLevel.nameEN + "," + item.faculty + " " + item.fieldOfStudy + " "}
                                                            </span>
                                                            <div className="template-0-experience-date-control">
                                                                <FontAwesomeIcon icon={faCalendarDays} className="template-0-experience-date-icon"></FontAwesomeIcon>

                                                                <span className="template-0-experience-date-text">
                                                                    {
                                                                        item.startYear + " - " + item.graduationYear

                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="template-0-experience-location-control">
                                                                <FontAwesomeIcon icon={faLocationDot} className="template-0-experience-location-icon"></FontAwesomeIcon>

                                                                <span className="template-0-experience-location-text">
                                                                    {item.institute}
                                                                </span>
                                                            </div>
                                                            <div className="template-0-experience-responsibility-control">
                                                                <span className="template-0-experience-responsibility-text">
                                                                    GPAX: {item.gpax}
                                                                </span>
                                                            </div>
                                                            <Divider className="template-0-experience-item-divider"></Divider>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </div>
                                    }

{
                                        customResume.skills.isShow &&
                                        <div className="template-0-experience-control">
                                            <span className="template-0-title-section" style={{ color: customResume.textPrimaryColor }}>Skills</span>
                                            <Divider className="template-0-divider" style={{ borderColor: customResume.textPrimaryColor }}></Divider>
                                            {

                                                customResume.skills.result.map((item: Skill, index: number) => {

                                                    return (
                                                        <ul className="template-0-experience-item" key={index}>
                                                                <li> {item.skillName}</li>
                                                           
                                                        </ul>

                                                    )
                                                })
                                            }

                                        </div>
                                    }
                                   

                                </div>
                            </div>


                        </>
                    }

                </div>

            </div>
        </>
    )
}

export default Template0Component;