export const EDUCATION_LIST = [
    {
        name: "No Education", value: "NO_EDUCATION", status: false
    },
    {
        name: "Primary School", value: "PRIMARY_SCHOOL", status: false
    },
    {
        name: "Lower Secondary School", value: "LOWER_SECONDARY_SCHOOL", status: false
    },
    {
        name: "Upper Secondary School", value: "UPPER_SECONDARY_SCHOOL", status: false
    },
    {
        name: "Diploma", value: "DIPLOMA", status: false
    },
    {
        name: "Bachelor", value: "BACHELOR", status: false
    },
    {
        name: "Master or Higher", value: "MASTER_OR_HIGHER", status: false
    },
    {
        name: "Vocational", value: "VOCATIONAL", status: false
    },
]