import { Button, Modal } from "react-bootstrap";
import "./top-up.modal.scss";
import TOPUP from "../assets/images/top-up.png";
import { useContext, useEffect, useState } from "react";

import UtilityService from "../utils/utilities";
import GAConfig from "../ga-config";
import { useNavigate } from "react-router-dom";
import templateConfig from "../template-config";


const TopupModal = ({ open, handleClose }: any) => {

    const navigate = useNavigate();
 


    const clickTel = () => {
        UtilityService().clickSendEvent("tel_topup_to_admin", "click")
        window.open("tel:020382639");
    }


    const clickEmail = () => {
        UtilityService().clickSendEvent("email_topup_admin", "click")
        window.open("mailto:customerservice@scoutout.net?subject="+templateConfig.text.contact_email);
    }

    const clickPricing = () => {
        UtilityService().clickSendEvent("pricing_page", "click")
        navigate("/pricing");
    }

    return (

        <>
            <Modal show={open} onHide={handleClose} >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="">

                    <div className="topup-modal">
                        <div className="topup-control">
                            <img src={TOPUP} className="topup-img"></img>
                            <Button className="topup-buy-button" onClick={clickPricing}>ไปยังหน้าซื้อเครดิต</Button>
                            <span className="topup-text">หากคุณต้องการเติมเครดิตหรือมีคำถามเกี่ยวกับบริการของเรา</span>
                            <span className="topup-text">กรุณาติดต่อเราที่หมายเลขโทรศัพท์ <span className="topup-link" onClick={clickTel}>02-038-2639</span></span>
                            <span className="topup-text">หรือ อีเมล <span className="topup-link" onClick={clickEmail}>customerservice@scoutout.net</span></span>
                            <br>
                            </br>
                            <span className="topup-text">ทีมงานของเรายินดีที่จะช่วยเสมอครับ</span>
                        </div>
                    </div>


                </Modal.Body>


            </Modal>
        </>
    )
}

export default TopupModal;