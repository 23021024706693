import apiConfig from "../api-config";
import config from "../config";
import instance from "./auth-context.service";
import instanceGPT from "./auth-gpt.service";
import instanceResume from "./auth-resume.service";
import instanceTalentSearch from "./auth-talentsearch.service";
import tokenService from "./token.service";


const postCandidate = (id) => {
    let headers = {};
    if (config.app_name == "TALENTSAUCE") {
        headers = {
            'content-type': 'application/json'
        }
    } else {
        headers = {
            'content-type': 'application/json',
            'x-api-key': config.talentSeach.x_api_key
        }
    }
    return instanceTalentSearch.get(apiConfig.talent_search.candidate + "/" + id, headers);
};
const getHistory = () => {
    let headers = {};
    if (config.app_name == "TALENTSAUCE") {
        headers = {
            'content-type': 'application/json'
        }
    } else {
        headers = {
            'content-type': 'application/json',
            'x-api-key': config.talentSeach.x_api_key
        }
    }
    return instanceTalentSearch.get(apiConfig.talent_search.history, headers);
};
const getRecommendation = () => {
    let headers = {};
    if (config.app_name == "TALENTSAUCE") {
        headers = {
            'content-type': 'application/json'
        }
    } else {
        headers = {
            'content-type': 'application/json',
            'x-api-key': config.talentSeach.x_api_key
        }
    }
    return instanceTalentSearch.get(apiConfig.talent_search.recommend, headers);
};

const getFavorite = (source_key) => {
    let headers = {};
    let url = config.talentSeach.base_url;

    headers = {
        'Source-Key': source_key,
        'x-api-key': config.talentSeach.x_api_key,
        'content-type': 'application/json'
    }
    return instanceTalentSearch.get("/bookmarks", headers);
}

const saveFavorite = (source_key, candidateID) => {
    let headers = {};

    headers = {
        'Source-Key': source_key,
        'x-api-key': config.talentSeach.x_api_key,
        'content-type': 'application/json'
    }
    return instanceTalentSearch.post("/candidate/"+candidateID+"/bookmark", headers);
}

const deleteFavorite = (source_key, candidateID) => {
    let headers = {};

    headers = {
        'Source-Key': source_key,
        'x-api-key': config.talentSeach.x_api_key,
        'content-type': 'application/json'
    }
    return instanceTalentSearch.delete("/candidate/"+candidateID+"/bookmark", headers);
}

const downloadResume = (bucket: string, key: string, candidate_id, user_name) => {
    let headers = {};
    if (config.app_name == "TALENTSAUCE") {
        headers = {
            'content-type': 'application/json'
        }
    } else {
        headers = {
            'content-type': 'application/json',
            'x-api-key': config.talentSeach.x_api_key
        }
    }

    return instanceTalentSearch.get("/resume" + "?" + new URLSearchParams({
        bucket: bucket,
        key: key,
        candidate_id: candidate_id,
        user_name: user_name,
    }), headers)
}
const TalentSearchService = {
    postCandidate,
    getHistory,
    getRecommendation,
    downloadResume,
    getFavorite,
    saveFavorite,
    deleteFavorite
};

export default TalentSearchService;