const apiConfig = {
    product_name : "/hr_clinic",
    chat_gpt: {
        path: {
            question: "/question",
            result: "/result",
            jd_suggest: "/job_suggestion",
            jd_generate: "/job_description/generate",
            jd_analyze: "/job_description/analyze",
            get_credit: "/credit",
            job_post_generate: "/job_post/generate",
            job_post_apply: "/job_post/{job_post_uuid}/apply",
            job_post: "/job_post/",
            log: "/log/",
            use_credit: "/credit/use/",
            company_profile: "/company/profile",
            post_generator: "/job_description/generate2"
        }
    },
    leela: {
        profile: "/api/v1/manager/profile",
        register_profile: "/api/v1/register/manager",
        register_company: "/api/v1/register/company",
        dashboard: "/api/v1/dashboard/jobs",
        job: "/api/v1/jobs/",
        candidate_view: "/api/v1/candidates/"
    },
    menu: {
        profile: "/profile",
        history_credit: "/history-credit",
        talent_search: "/talent-search",
        jd_generator: "/jd-generator",
        jd_checker: "/jd-checker",
        post_job_social: "/post-job-social",
        resume_prescreen: "/resume-prescreen",
        interview_questions_generator: "/interview-questions-generator",
        salary_offering: "/salary-offer",
        crm: "/crm-line-oa",
        ats: "/ats"
    },
    resume_checker: "/resume-checker",
    jd_suggest: "/job_suggestion",
    upload_resume: "/resume/upload",
    crm: {
        audiences: "/audiences",
        broadcasts: "/broadcasts",
        requests: "/requests",
        insight: "/insight/followers",
        sheet_import: "/sheet/import",
        sheet: "/sheet"
    },
    talent_search: {
        candidate: "/candidate",
        history: "/credit/history",
        recommend: "/recommend"
    }
   

}


export default {
    // Add common config values here
    ...apiConfig
};