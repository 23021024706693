import { Button } from "react-bootstrap";
import TalentSauceHeaderComponent from "../header/talent-sauce-header";
import "./talent-sauce-pricing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import CREDIT_USAGE_JSON from "../../../../assets/json/talent-sauce/credit-usage.json";
import CREDIT_USAGE_JSON_TH from "../../../../assets/json/talent-sauce/credit-usage-th.json";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import PACKAGE_PRICING_JSON from "../../../../assets/json/talent-sauce/pricing.json";
import TalentSauceFooterComponent from "../footer/talent-sauce-footer";
import UtilityService from "../../../../utils/utilities";
import EARLY_BIRD_IMAGE from "../../../../assets/images/techsauce/early-bird.png";
import { useLanguage } from "../../../../context/language.context";
import JSON_LANG_TH from "../../../../assets/json/talent-sauce/pricing-th.json";
import JSON_LANG_EN from "../../../../assets/json/talent-sauce/pricing-en.json";
import PRODUCT_IMG_TAG_CRS from "../../../../assets/images/techsauce/tag-crs.png";
import PRODUCT_ICON_IMG_1 from "../../../../assets/images/techsauce/product-icon-1.png";
import PRODUCT_ICON_IMG_2 from "../../../../assets/images/techsauce/product-icon-2.png";
import PRODUCT_ICON_IMG_3 from "../../../../assets/images/techsauce/product-icon-3.png";
import PRODUCT_ICON_IMG_4 from "../../../../assets/images/techsauce/product-icon-4.png";
import PRODUCT_IMG_RIGHT_1 from "../../../../assets/images/techsauce/product-img-1.png";
import PRODUCT_IMG_RIGHT_3 from "../../../../assets/images/techsauce/product-img-3.png";
import PRODUCT_IMG_RIGHT_4 from "../../../../assets/images/techsauce/product-img-4.png";
import PRODUCT_IMG_1 from "../../../../assets/images/techsauce/product-logo-1.png";
import PRODUCT_IMG_2 from "../../../../assets/images/techsauce/product-logo-2.png";
import PRODUCT_IMG_3 from "../../../../assets/images/techsauce/product-logo-3.png";
import PRODUCT_IMG_4 from "../../../../assets/images/techsauce/product-logo-4.png";
import PRODUCT_IMG_5 from "../../../../assets/images/techsauce/product-logo-5.png";
import PRODUCT_IMG_6 from "../../../../assets/images/techsauce/product-logo-6.png";
import PRODUCT_IMG_7 from "../../../../assets/images/techsauce/product-logo-7.png";
import PRODUCT_IMG_8 from "../../../../assets/images/techsauce/product-logo-8.png";
import PRODUCT_IMG_9 from "../../../../assets/images/techsauce/product-logo-9.png";
import ReactPlayer from 'react-player';
const TalentSaucePricingComponent = () => {

    const [creditJson, setCreditJson] = useState<any>(CREDIT_USAGE_JSON);
    const { language, setLanguage } = useLanguage();
    const [translate, setTranslate] = useState(JSON_LANG_EN)
    useEffect(() => {

        window.onload = function () {
            window.scrollTo(0, 0);
        };
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (language) {
            if (language == 'en') {
                setCreditJson(CREDIT_USAGE_JSON)
                setTranslate(JSON_LANG_EN)
            } else {
                setCreditJson(CREDIT_USAGE_JSON_TH)
                setTranslate(JSON_LANG_TH)
            }
        }
    }, [language])
    const clickOpen = (index) => {

        const creditJsonMod = [...creditJson];
        console.log(creditJsonMod)
        if (creditJsonMod[index].is_open) {
            creditJsonMod[index].is_open = false;
        } else {
            creditJsonMod[index].is_open = true;
        }

        setCreditJson(creditJsonMod);
        UtilityService().clickSendEvent("open_faq_" + index, "click");



    }

    const getQuotation = () => {
        UtilityService().clickSendEvent("get_quotation", "click");
        window.open("https://forms.gle/MhKWxKdcxX9avfnr9", "_blank");
    }
    const clickContact = () => {

        UtilityService().clickSendEvent("contact_us", "click");
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScNusrvXlQJwVKB2jl-e6_FXexXoWZkhmpA09U7VK8a5fo0mQ/viewform", "_blank");
    }

    const clickBuy = (item) => {
        UtilityService().clickSendEvent("buy_package_" + item.pricing, "click");
        window.open(item.link_buy, "_blank");
    }

    const clickLINEOA = (item) => {
        UtilityService().clickSendEvent("click_line_oa_"+item, "click");
        window.open("https://lin.ee/HwNciwt", "_blank")
    }
    const clickLINEOACONTACT = () => {
        UtilityService().clickSendEvent("click_line_oa_contact", "click");
        window.open("https://lin.ee/HwNciwt", "_blank")
    }
    return (

        <>
            <div className="talent-sauce-pricing-main">
                <div className="talent-sauce-pricing-overlay-black"></div>
                <div className="talent-sauce-pricing-cover-img"></div>
                <div className="talent-sauce-pricing-cover-header-control">

                    <TalentSauceHeaderComponent></TalentSauceHeaderComponent>
                    <div className="talent-sauce-pricing-product-control">
                        <span className="talent-sauce-pricing-text">{translate.product.title}</span>
                        <div className="talent-sauce-pricing-grid">
                            <div className="talent-sauce-pricing-grid-item">
                                <div className="talent-sauce-pricing-grid-tag">
                                    <img src={PRODUCT_ICON_IMG_1} className="talent-sauce-pricing-grid-tag-icon"></img>
                                    <span className="talent-sauce-pricing-grid-tag-text">{translate.product.tag[0].tag_title}</span>
                                </div>
                                <span className="talent-sauce-pricing-grid-title">{translate.product.tag[0].title}</span>
                                <div className="talent-sauce-pricing-grid-content-control">
                                    <div className="talent-sauce-pricing-grid-content-left">
                                        <span className="talent-sauce-pricing-grid-content-what-did">{translate.product.what_we_did}</span>

                                        <div className="talent-sauce-pricing-grid-content-logo-control">
                                            <img src={PRODUCT_IMG_1} className="talent-sauce-pricing-grid-content-logo"></img>
                                            <img src={PRODUCT_IMG_2} className="talent-sauce-pricing-grid-content-logo"></img>
                                            <img src={PRODUCT_IMG_3} className="talent-sauce-pricing-grid-content-logo"></img>
                                        </div>
                                        <img src={PRODUCT_IMG_RIGHT_1} className="talent-sauce-pricing-grid-content-left-img"></img>

                                        <Button className="talent-sauce-pricing-grid-chat-button" onClick={() => clickLINEOA("01")}>
                                            {translate.product.chat_with_us}
                                            <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-grid-chat-icon"></FontAwesomeIcon>
                                        </Button>

                                    </div>
                                    <div className="talent-sauce-pricing-grid-content-right">
                                    <img src={PRODUCT_IMG_RIGHT_1} className="talent-sauce-pricing-grid-content-right-img"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="talent-sauce-pricing-grid-item">
                                <div className="talent-sauce-pricing-grid-tag">
                                    <img src={PRODUCT_ICON_IMG_2} className="talent-sauce-pricing-grid-tag-icon"></img>
                                    <span className="talent-sauce-pricing-grid-tag-text">{translate.product.tag[1].tag_title}</span>
                                </div>
                                <span className="talent-sauce-pricing-grid-title">{translate.product.tag[1].title}</span>
                                <div className="talent-sauce-pricing-grid-content-control">
                                    <div className="talent-sauce-pricing-grid-content-left">
                                        <span className="talent-sauce-pricing-grid-content-what-did">{translate.product.what_we_did}</span>
                                        <div className="talent-sauce-pricing-grid-content-logo-control">
                                            <img src={PRODUCT_IMG_4} className="talent-sauce-pricing-grid-content-logo"></img>
                                            <div className="d-none d-md-flex"></div>
                                            <img src={PRODUCT_IMG_5} className="talent-sauce-pricing-grid-content-logo"></img>
                                        </div>
                                        <div className="talent-sauce-pricing-grid-content-left-video-control">
                                            <img src={PRODUCT_IMG_TAG_CRS} className="talent-sauce-pricing-grid-content-left-video-tag"></img>
                                            <ReactPlayer
                                                url='/assets/video/centara.mp4'
                                                controls={true}
                                                playsinline
                                                loop
                                                playing
                                                width='100%'
                                                height='100%'
                                                className="talent-sauce-pricing-grid-content-right-video"
                                            />
                                        </div>


                                        <Button className="talent-sauce-pricing-grid-chat-button"  onClick={() => clickLINEOA("02")}>
                                            {translate.product.chat_with_us}
                                            <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-grid-chat-icon"></FontAwesomeIcon>
                                        </Button>

                                    </div>
                                    <div className="talent-sauce-pricing-grid-content-right">
                                        <div className="talent-sauce-pricing-grid-content-right-video-control">
                                        <img src={PRODUCT_IMG_TAG_CRS} className="talent-sauce-pricing-grid-content-right-video-tag"></img>
                                            <ReactPlayer
                                                url='/assets/video/centara.mp4'
                                                controls={false}
                                                playsinline
                                                loop
                                                muted
                                                playing={true}
                                                width='100%'
                                                height='100%'
                                                className="talent-sauce-pricing-grid-content-right-video"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="talent-sauce-pricing-grid-item">
                                <div className="talent-sauce-pricing-grid-tag">
                                    <img src={PRODUCT_ICON_IMG_3} className="talent-sauce-pricing-grid-tag-icon"></img>
                                    <span className="talent-sauce-pricing-grid-tag-text">{translate.product.tag[2].tag_title}</span>
                                </div>
                                <span className="talent-sauce-pricing-grid-title">{translate.product.tag[2].title}</span>
                                <div className="talent-sauce-pricing-grid-content-control">
                                    <div className="talent-sauce-pricing-grid-content-left">
                                        <span className="talent-sauce-pricing-grid-content-what-did">{translate.product.what_we_did}</span>
                                        <div className="talent-sauce-pricing-grid-content-logo-control">
                                            <img src={PRODUCT_IMG_6} className="talent-sauce-pricing-grid-content-logo"></img>
                                            <div className="d-none d-md-flex"></div>
                                            <img src={PRODUCT_IMG_7} className="talent-sauce-pricing-grid-content-logo"></img>
                                        </div>
                                        <img src={PRODUCT_IMG_RIGHT_3} className="talent-sauce-pricing-grid-content-left-img"></img>
                                        <Button className="talent-sauce-pricing-grid-chat-button"  onClick={() => clickLINEOA("03")}>
                                            {translate.product.chat_with_us}
                                            <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-grid-chat-icon"></FontAwesomeIcon>
                                        </Button>

                                    </div>
                                    <div className="talent-sauce-pricing-grid-content-right">
                                        <img src={PRODUCT_IMG_RIGHT_3} className="talent-sauce-pricing-grid-content-right-img"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="talent-sauce-pricing-grid-item">
                                <div className="talent-sauce-pricing-grid-tag">
                                    <img src={PRODUCT_ICON_IMG_4} className="talent-sauce-pricing-grid-tag-icon"></img>
                                    <span className="talent-sauce-pricing-grid-tag-text">{translate.product.tag[3].tag_title}</span>
                                </div>
                                <span className="talent-sauce-pricing-grid-title">{translate.product.tag[3].title}</span>
                                <div className="talent-sauce-pricing-grid-content-control">
                                    <div className="talent-sauce-pricing-grid-content-left">
                                        <span className="talent-sauce-pricing-grid-content-what-did">{translate.product.what_we_did}</span>

                                        <div className="talent-sauce-pricing-grid-content-logo-control">
                                            <img src={PRODUCT_IMG_8} className="talent-sauce-pricing-grid-content-logo"></img>
                                            <div className="d-none d-md-flex"></div>
                                            <img src={PRODUCT_IMG_9} className="talent-sauce-pricing-grid-content-logo"></img>
                                        </div>
                                        <img src={PRODUCT_IMG_RIGHT_4} className="talent-sauce-pricing-grid-content-left-img"></img>
                                        <Button className="talent-sauce-pricing-grid-chat-button"  onClick={() => clickLINEOA("04")}>
                                            {translate.product.chat_with_us}
                                            <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-grid-chat-icon"></FontAwesomeIcon>
                                        </Button>

                                    </div>
                                    <div className="talent-sauce-pricing-grid-content-right">
                                        <img src={PRODUCT_IMG_RIGHT_4} className="talent-sauce-pricing-grid-content-right-img"></img>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="talent-sauce-pricing-product-scroll-control">

                            <span className="talent-sauce-pricing-product-scroll-text">{translate.product.scroll}</span>
                            <FontAwesomeIcon icon={faArrowDown} className="talent-sauce-pricing-product-scroll-icon"></FontAwesomeIcon>
                        </div>
                    </div>
                    <div className="talent-sauce-pricing-cover-header-text-control">

                        <div className="talent-sauce-pricing-title-control">
                            <div className="d-flex flex-column">
                                <span className="talent-sauce-pricing-title-text">{translate.title}</span>
                                <span className="talent-sauce-pricing-desc-text">
                                    {translate.desc} <span className="talent-sauce-pricing-desc-text-contact" onClick={clickContact}>{translate.contact_us}</span>
                                </span>
                                <span className="talent-sauce-pricing-desc-text">
                                    {translate.contact_or} <span className="talent-sauce-pricing-desc-text-contact" onClick={clickLINEOACONTACT}>{translate.contact_line_oa}</span>   {translate.contact_line_oa_text}
                                </span>
                            </div>
                            <Button className="talent-sauce-pricing-quotation-button" onClick={getQuotation}>{translate.get_quotation}</Button>
                        </div>
                        <div className="talent-sauce-pricing-grid-control">
                            {

                                PACKAGE_PRICING_JSON.map((item) => {

                                    return (


                                        <div className="talent-sauce-pricing-item-control">
                                            <div className=
                                                {
                                                    item.name == "Business" ? "talent-sauce-pricing-item talent-sauce-pricing-item-highlight" : "talent-sauce-pricing-item"
                                                }
                                            >
                                                <img src={EARLY_BIRD_IMAGE} className="talent-sauce-pricing-early-tag"></img>
                                                <span
                                                    className={
                                                        item.name == "Business" ? "talent-sauce-pricing-package talent-sauce-pricing-highlight-color-white"
                                                            : "talent-sauce-pricing-package"
                                                    }
                                                >{item.name}</span>
                                                <div className="d-flex">
                                                    <span className={
                                                        item.name == "Business" ? "talent-sauce-pricing-baht-sign-standard talent-sauce-pricing-highlight-color" : "talent-sauce-pricing-baht-sign-standard"
                                                    }
                                                    >฿
                                                        <span
                                                            className={
                                                                item.name == "Business" ? "talent-sauce-pricing-baht-value-standard talent-sauce-pricing-highlight-color"
                                                                    : "talent-sauce-pricing-baht-value-standard"
                                                            }
                                                        >{item.standard_pricing.toLocaleString()}</span></span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className={
                                                        item.name == "Business" ? "talent-sauce-pricing-baht-sign talent-sauce-pricing-highlight-color"
                                                            : "talent-sauce-pricing-baht-sign"
                                                    }

                                                    >฿<span className="talent-sauce-pricing-baht-value">{item.pricing.toLocaleString()}</span></span>
                                                </div>
                                                <span className="talent-sauce-pricing-months">{item.duration} {translate.month}</span>

                                                <span className="talent-sauce-pricing-credits">{(item.standard_credit + item.credit_ontop).toLocaleString()} <span className="talent-sauce-pricing-credits-ontop-gray">{translate.credits}</span></span>
                                                {
                                                    item.credit_ontop > 0 ?
                                                        <span
                                                            className={
                                                                item.name == "Business" ? "talent-sauce-pricing-credits-ontop talent-sauce-pricing-highlight-color-white"
                                                                    : "talent-sauce-pricing-credits-ontop"
                                                            }>
                                                            <span
                                                                className={
                                                                    item.name == "Business" ? "talent-sauce-pricing-credits-ontop-gray talent-sauce-pricing-highlight-color-white"
                                                                        : "talent-sauce-pricing-credits-ontop-gray"
                                                                }>
                                                                ({(item.standard_credit).toLocaleString()}</span>+{item.credit_ontop.toLocaleString()} {translate.free_credit} )
                                                        </span>
                                                        :
                                                        <></>
                                                }

                                                <Button className=
                                                    {
                                                        item.name == "Business" ? "talent-sauce-pricing-button talent-sauce-pricing-highlight-color-button"
                                                            : "talent-sauce-pricing-button"
                                                    } onClick={() => clickBuy(item)}>{translate.buy_now}
                                                    <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-icon"></FontAwesomeIcon></Button>
                                            </div>
                                        </div>

                                    )
                                })
                            }


                            {/* // <div className="talent-sauce-pricing-item-control">
                                            //     <div className="talent-sauce-pricing-item-highlight">
                                            //         <span className="talent-sauce-pricing-package">{item.name}</span>
                                            //         <div className="d-flex">
                                            //             <span className="talent-sauce-pricing-baht-sign-standard">฿
                                            //             <span className="talent-sauce-pricing-baht-value-standard">{item.standard_pricing.toLocaleString()}</span></span>
                                            //         </div>
                                            //         <div className="d-flex">
                                            //             <span className="talent-sauce-pricing-baht-sign talent-suace-pricing-white-label">฿<span className="talent-sauce-pricing-baht-value">36,280</span></span>
                                            //         </div>
                                            //         {
                                            //             item.is_early_bird &&             <span className="talent-sauce-pricing-early-bird">(Early Bird)</span>
                                            //         }
                                        
                                            //         <span className="talent-sauce-pricing-credits talent-suace-pricing-white-label">{item.standard_credit.toLocaleString()} Credits</span>
                                            //         {
                                            //         item.credit_ontop > 0 ?
                                            //         <span className="talent-sauce-pricing-credits-ontop-highlight">(+{item.credit_ontop} Credits on top )</span>
                                            //         :
                                            //         <span className="talent-sauce-pricing-credits-ontop-highlight">(No credits ontop)</span>
                                            //        }
                                            //        <span className="talent-sauce-pricing-months">{item.duration} Months</span>
                                            //         <Button className="talent-sauce-pricing-button-highlight">Get start
                                            //             <FontAwesomeIcon icon={faArrowRight} className="talent-sauce-pricing-icon"></FontAwesomeIcon></Button>
                                            //     </div>
                                            // </div> */}


                        </div>
                    </div>
                </div>
                <div className="talent-sauce-pricing-section-2-control">
                    <div className="talent-sauce-pricing-section-2-content-control">
                        <span className="talent-sauce-pricing-section-2-title">
                            {translate.faq.title}
                        </span>
                        <span className="talent-sauce-pricing-section-2-desc">
                            {translate.faq.desc}
                        </span>
                        {
                            creditJson && creditJson.map((item: any, index: number) => {

                                return (
                                    <div className="talent-sauce-pricing-section-content-item" key={index}>
                                        <div className="talent-sauce-pricing-section-content-item-header-control" onClick={() => clickOpen(index)}>
                                            <span className="talent-sauce-pricing-section-content-item-header">{item.title}</span>
                                            <FontAwesomeIcon

                                                icon={item.is_open ? faChevronUp : faChevronDown} className="talent-sauce-pricing-section-content-item-icon"></FontAwesomeIcon>
                                        </div>
                                        {
                                            item.is_open &&
                                            <div className="talent-sauce-pricing-section-content-item-desc-control">
                                                <span className="talent-sauce-pricing-section-content-item-desc"
                                                    dangerouslySetInnerHTML={{ __html: item.html_desc }}></span>
                                            </div>
                                        }

                                        <Divider className="talent-sauce-pricing-section-content-item-divider"></Divider>
                                    </div>
                                )
                            })
                        }
                        <div className="talent-sauce-pricing-section-2-contact">
                            <span className="talent-sauce-pricing-section-2-title">{translate.more_info_1} <span className="talent-sauce-pricing-section-2-orange" onClick={clickContact}>{translate.contact_us}</span></span>

                        </div>

                    </div>
                </div>
                <TalentSauceFooterComponent></TalentSauceFooterComponent>
            </div>
        </>
    )
}

export default TalentSaucePricingComponent;