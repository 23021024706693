import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useRef, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import InputJobPostModel from "../../../model/job-post/input-job-post.model";
import PostJobModel from "../../../model/job-post/job-post.model";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import EXAMPLE_JSON from "../../../assets/json/example/post-job.json";
import LoadingComponent from "../loading/loading";
import ShareJobComponent from "./share/share-job";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
const PostJobSocialComponent = ({ profileModel, submitWithOutCredit, authenError }: any) => {
    const navigate = useNavigate();
    const { creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobExperience, setJobExperience] = useState("");
    const [jobSkill, setJobSkill] = useState("");
    const [language, setLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(EXAMPLE_JSON["result"]);
    const [isExample, setIsExample] = useState(true);
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isShare, setIsShare] = useState(false);
    const [jobDescription, setJobDescription] = useState("");
    const [social, setSocial] = useState("");
    const [jobSalary, setJobSalary] = useState("");
    const [questionAmount, setQuestionAmount] = useState("");
    const [workingHours, setWorkingHours] = useState("");
    const [emailApply, setEmailApply] = useState("");
    const [location, setLocation] = useState("");
    const [language_type, setLanguageType] = useState("");
    const [benefit, setBenefit] = useState("");
    const [company, setCompany] = useState("");
    const [isShareImg, setIsShareImg] = useState(false);
    const [inputJobPost, setInputJobPost] = useState<InputJobPostModel>();
    const [isShowModalShareImg, setIsShowModalShareImg] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRef.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRef && textFieldRef.current) {
                    textFieldRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRef]);
    const resetALL = () => {
        setJobSkill("");
        setJobTitle("");
        setSocial("");
        setJobDescription("");
        setJobExperience("");
        setJobSalary("");
        setQuestionAmount("");
        setWorkingHours("");
        setEmailApply("");
        setLocation("");
        setLanguageType("");
        setLanguage("");
        setBenefit("");
        setResult("");
        setCompany("");
        setIsValidate(false);
    }
    const handleLanguageType = (e) => {
        setLanguageType(e.target.value)
    }


    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {

            if (res) {
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }

        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }
    const setShowShare = (inputJobPostMod) => {
        setInputJobPost(inputJobPostMod)
        console.log("Share Img")
        setIsShowModalShareImg(true);

    }
    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobExperience && Number(jobExperience) >= 0 && location && emailApply && company && social && language) {
            setIsValidate(true);
            setIsLoading(true);
            setResult("")
            let strArr: string[] = [];



            const objectResult: InputJobPostModel = {
                job_title: jobTitle,
                social: social,
                experience: jobExperience,
                location: location,
                working_hours: workingHours,
                email_apply: emailApply,
                benefits: benefit,
                company: company,
                salary: jobSalary,
                skills: jobSkill,
                language: language,
                language_type: language_type,
                uuid: profileMod!.uuid
            }

            console.log(objectResult);

            GPTService.postJob(objectResult).then((res) => {
                if (res) {
                    if (res.data == "500") {
                        setResult("500")
                        setIsSubmiting(false);
                    }else if(res.status == 401){
                        authenError();
                    }else{
                        const answerModel: PostJobModel = res.data;
                        const jobPostModel = answerModel as PostJobModel;
                        ConvertResultServices().JobPostSocial(answerModel).then((resultTemp) => {
                            if (resultTemp != "500") {
    
                                setIsShare(true);
                                setIsLoading(false);
                                setIsShareImg(true);
                                setIsSubmiting(false);
                                console.log(resultTemp);
                                objectResult.url_job = config.app.home + "job/" + jobPostModel.job_post_uuid;
                                let strMsg = jobPostModel.job_post_generated + "";
                                strMsg += "<br><br>";
                                strMsg += "Apply URL : " + "<a href='" + config.app.home + "job/" + jobPostModel.job_post_uuid + "' target='_blank'> คลิก </a>";
                                UtilityService().clickSendEvent("job_post_caption_generator_" + objectResult.job_title, "click")
    
                                setResult(strMsg);
                                setResultRawString(strMsg);
                                setIsExample(false);
                                setInputJobPost(objectResult);
                                GPTService.getCredit().then((res3) => {
    
                                    if(res3){
                                        setCreditModel(res3.data)
                                    }
                                })
                            } else {
                                setResult("500")
                                setIsSubmiting(false);
                            }
    
                        });
                    }
                  
                }
                
            }).catch((err) => {
                setResult("500")
                setIsSubmiting(false);
            })

        }
    }
    const handleSocial = (e) => {
        setSocial(e.target.value);
    }


    const handleCloseShowShareImg = () => {

        setIsShowModalShareImg(false);
    }
    const handleWheel = (event) => {
        // This prevents the scroll wheel from changing the number
        event.preventDefault();
    };
    return (

        <>
            <div className="feature-control">


                <div className="feature-form-control">
                    <div className="feature-form-left-control">
                        <h1 className="feature-main-title">{languageConfig.content.post_job_social.title}</h1>
                        <span className="feature-main-desc">{languageConfig.content.post_job_social.description}</span>
                        <div className="feature-credit-usage-control">
                            <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                            <span className="feature-credit-usage-text">ใช้เครดิต:
                                <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>
                        </div>
                        {
                            isValidate ?

                                <>
                                    <div className="dashboard-form-item-submit-control">
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Title (ตำแหน่งงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobTitle}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobExperience}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Location (สถานที่ทำงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {location}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Working Hours (เวลาทำงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {workingHours}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Email apply (Email สำหรับส่งใบสมัคร)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {emailApply}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Benefit (สวัสดิการของบริษัท)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {benefit}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Company (ชื่อบริษัท)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {company}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Salary (เงินเดือนสำหรับงานนี้)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobSalary}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Qualification (คุณสมบัติ)
                                            </div>
                                            <span className="dashboard-form-item-submit-desc-sub">ระบุคุณสมบัติ หรือ ทักษะความสามารถที่ต้องการ</span>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobSkill}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                ภาษาที่ต้องการสร้าง Job Post
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {language}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                ช่องทางการโพสต์
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {social}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                รูปแบบการใช้คำในโพสต์
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {language_type}
                                            </div>
                                        </div>

                                        <div className="dashboard-form-item-submit-item">
                                            <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                        </div>
                                    </div>

                                </> :
                                <div className="dashboard-form-item-control">
                                    <div className="dashboard-form-click-2-columns">
                                        <div className="dashboard-form-item" >
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Title (ตำแหน่งงาน) <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Group>

                                                <Typeahead
                                                    onChange={(e) => handleJobRoleChange(e)}
                                                    id="valid-styles-example"
                                                    inputProps={{
                                                        className: 'dashboard-input',
                                                    }}
                                                    options={autocompleteList}
                                                    allowNew
                                                />
                                            </Form.Group>
                                            {
                                                (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Job title
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้) <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Control
                                                ref={textFieldRef}
                                                type="number" min={0} max={100} className="dashboard-input" onChange={(e) => onTextChange(e, setJobExperience)}></Form.Control>
                                            {
                                                (!jobExperience && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Job Experience
                                                </Form.Text>
                                            }
                                            {
                                                (Number(jobExperience) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    ไม่สามารถกรอกค่าน้อยกว่า 0
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Location (สถานที่ทำงาน) <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setLocation)}></Form.Control>
                                            {
                                                (!location && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Location
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Working Hours (เวลาทำงาน)</Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setWorkingHours)}></Form.Control>

                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Email apply (Email สำหรับส่งใบสมัคร) <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setEmailApply)}></Form.Control>
                                            {
                                                (!emailApply && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Email สำหรับส่งใบสมัคร
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Benefit (สวัสดิการของบริษัท)</Form.Label>

                                            <Form.Control as="textarea" className="dashboard-input-textarea" cols={10} rows={10} onChange={(e) => onTextChange(e, setBenefit)}></Form.Control>

                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Company (ชื่อบริษัท) <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setCompany)}></Form.Control>
                                            {
                                                (!company && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณากรอก Company
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Salary (เงินเดือนสำหรับงานนี้)</Form.Label>

                                            <Form.Control type="text" className="dashboard-input" onChange={(e) => onTextChange(e, setJobSalary)}></Form.Control>

                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Qualification (คุณสมบัติ)</Form.Label>
                                            <span className="dashboard-form-item-desc">ระบุคุณสมบัติ หรือ ทักษะความสามารถที่ต้องการ</span>
                                            <Form.Control as="textarea" className="dashboard-input-textarea" cols={10} rows={10} onChange={(e) => onTextChange(e, setJobSkill)}></Form.Control>

                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">ภาษาที่ต้องการสร้าง Job Post <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Select className="dashboard-input" onChange={handleLanguageChange} >
                                                <option value="">เลือกภาษา</option>
                                                <option value="THAI" >ไทย (Beta version)</option>
                                                <option value="ENGLISH" >English</option>
                                            </Form.Select>
                                            {
                                                (!language && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณาเลือกภาษา
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">ช่องทางการโพสต์ <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Select className="dashboard-input" onChange={handleSocial} >
                                                <option value="">เลือกช่องทางการโพสต์</option>
                                                <option value="FACEBOOK" >Facebook</option>
                                                <option value="TWITTER" >Twitter</option>
                                            </Form.Select>
                                            {
                                                (!social && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณาเลือกช่องทางการโพสต์
                                                </Form.Text>
                                            }
                                        </div>
                                        <div className="dashboard-form-item">
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">รูปแบบการใช้คำในโพสต์ <span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            <Form.Select className="dashboard-input" onChange={(e) => handleLanguageType(e)} >
                                                <option value="">เลือกรูปแบบ</option>
                                                <option value="formal" >ทางการ</option>
                                                <option value="informal" >ไม่ทางการ</option>
                                                <option value="funny" >ติดตลก</option>
                                            </Form.Select>
                                            {
                                                (!language_type && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                    กรุณาเลือกรูปแบบการใช้คำในโพสต์
                                                </Form.Text>
                                            }
                                        </div>
                                    </div>
                                    {
                                        profileMod &&
                                        <div className="d-flex">
                                            {
                                                creditModel && creditModel.credit > 0 ?
                                                    <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.post_job_social.button}</Button>
                                                    : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.post_job_social.button}</Button>
                                            }


                                        </div>
                                    }

                                </div>
                        }
                    </div>
                    <div className="">{
                        isLoading && !result ? <>
                            <LoadingComponent feature={TRICK_JSON[2].loading} listTrick={TRICK_JSON[2]} ></LoadingComponent> :
                        </> :
                            result && <ResultClinicComponent type={languageConfig.content.post_job_social.title} isExample={isExample} resultStr={result} isClose={false} isShare={isShare} isShareImg={isShareImg} setShowShare={setShowShare} ></ResultClinicComponent>
                    }

                    </div>
                </div>
                {
                    <ShareJobComponent isOpen={isShowModalShareImg} handleClose={handleCloseShowShareImg} inputMod={inputJobPost}></ShareJobComponent>
                }
            </div>
        </>
    )
}

export default PostJobSocialComponent;