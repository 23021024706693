import { useNavigate } from "react-router-dom";
import templateConfig from "../../../../template-config";
import "./talent-sauce-footer.scss";
import { useLanguage } from "../../../../context/language.context";
import JSON_LANG_TH from "../../../../assets/json/talent-sauce/landing-th.json";
import JSON_LANG_EN from "../../../../assets/json/talent-sauce/landing-en.json";
import { useEffect, useState } from "react";

const TalentSauceFooterComponent= () => {
    const { language, setLanguage } = useLanguage();
    const navigate = useNavigate();
    const [translate, setTranslate] = useState(JSON_LANG_EN)
    const clickUrl = (url) => {

        navigate(url)
    }
    useEffect(() => {
        if(language){
            if (language == 'en') {
                setTranslate(JSON_LANG_EN)
            } else {
                setTranslate(JSON_LANG_TH)
            }
        }
    }, [language])

    return (
        <>
            <div className="talent-sauce-footer-main">
                <img src={templateConfig.assets_env.main_logo} 
                onClick={() => clickUrl("/")}
                className="talent-sauce-footer-logo-left"></img>
                <div className="talent-sauce-footer-terms-control">
                    <span className="talent-sauce-footer-terms-text" onClick={() => clickUrl("/terms")}>
                        {translate.footer.terms}
                    </span>
                    <span className="talent-sauce-footer-terms-text" onClick={() => clickUrl("/privacy")}>
                    {translate.footer.privacy}
                    </span>
                </div>
            </div>

        </>
    )
}

export default TalentSauceFooterComponent;