import axios from "axios"
import config from "../config"


export default function ArticlesServices() {

    return {
        async getRecommend(channel) {
            return axios.get(config.articles.base_url + config.articles.recommend + "?channel=" + channel, {
          headers: {
                    'Content-Type': "application/json"
                }
            }).then(res => res).catch(err => console.log(err))
        }
    }
}