
import { Container, Modal } from "react-bootstrap";
import "./share-job-ts.scss";
import "./share-job.scss";
import { useEffect, useRef, useState } from "react";
import InputJobPostModel from "../../../../model/job-post/input-job-post.model";
import SHARE_JOB_POST from "../../../../assets/images/share-job-post.png";
import SHARE_PIN from "../../../../assets/images/share-pin.png";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../../../header/header";
import config from "../../../../config";

const ShareJobComponent = ({isOpen, handleClose, inputMod} : any) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [inputObject, setInputObject] = useState<InputJobPostModel>();
    const navigate = useNavigate();

    useEffect(() => {
        if(inputMod){
            setInputObject(inputMod)
        }
    }, [inputMod])
    const handleDownload = () => {
        if (containerRef.current && inputObject && inputObject.job_title) {
            html2canvas(containerRef.current)
                .then((canvas) => {
                    // Convert the canvas to base64 image
                    const image = canvas.toDataURL('image/jpg');
                    canvas.toBlob(async (blob: any) => {
                        // Even if you want to share just one file you need to
                        // send them as an array of files.
                        const files2 = new File([blob], inputObject.job_title + '.png')

                        // Create a URL for the File object (or Blob object if you didn't create a File object)
                        const fileUrl = URL.createObjectURL(files2);

                        // Create a hidden link element
                        const downloadLink = document.createElement('a');
                        downloadLink.href = fileUrl;
                        downloadLink.download = inputObject.job_title + '.png';

                        // Trigger the click event to start the download
                        downloadLink.click();
                    })
                    // Create a temporary link element

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };
    const handleShare = () => {
        if (containerRef.current && inputObject && inputObject.job_title) {
            html2canvas(containerRef.current)
                .then((canvas) => {
                    // Convert the canvas to base64 image\
                    canvas.toBlob(async (blob: any) => {
                        // Even if you want to share just one file you need to
                        // send them as an array of files.
                        const files = [new File([blob], inputObject.job_title + '.png', { type: blob.type })]
                        const shareData = {
                            text: 'Share Job Post',
                            title: 'Share Job Post',
                            files,
                        }
                        if (navigator.canShare(shareData)) {
                            try {
                                await navigator.share(shareData)
                            } catch (err: any) {
                                if (err.name !== 'AbortError') {
                                    console.error(err.name, err.message)
                                }
                            }
                        } else {
                            console.warn('Sharing not supported', shareData)
                        }
                    })

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };
    return (

        <>
            <Modal show={isOpen} onHide={handleClose} dialogClassName="share-job">

                <Modal.Body  >
                    <div className="share-job">
                        <Container className="d-flex">
                            <div className="share-img-button-download-control">
                                <button className="share-img-button-download" onClick={handleDownload}>Download</button>
                                <button className="share-img-button-download" onClick={handleShare}>Share</button>
                            </div>
                        </Container>
                        <div className="share-img">

                            {
                                (inputObject && inputObject.job_title && inputObject.company && inputObject.location && inputObject.url_job) &&
                                <div className="share-img-control" ref={containerRef}>
                                    <span className=
                                        {inputObject.job_title.length > 30 ? "share-img-job-title-small" : "share-img-job-title"}
                                    >{inputObject.job_title}</span>
                                    <span className={inputObject.company.length > 25 ? "share-img-company-small" : "share-img-company"}>{
                                        inputObject.company.includes("บริษัท") ? "" : "บริษัท"
                                    } {inputObject.company}</span>
                                    {Number(inputObject.experience) == 0 ?
                                        <span className="share-img-exp">ไม่ต้องการประสบการณ์</span> :
                                        <span className="share-img-exp">ประสบการณ์
                                            <span className="share-img-exp-title">{inputObject.experience}</span> ปี</span>
                                    }


                                    {
                                        (inputObject.salary == '0' || inputObject.salary!.length <= 1) ?

                                            <>
                                                <span className=
                                                    {
                                                        inputObject.salary!.length > 15 ? "share-img-salary-small" : "share-img-salary"
                                                    }
                                                >

                                                    <span className="share-img-salary-title">เงินเดือน<span className="share-img-salary"> แล้วแต่ตกลง</span></span> </span> </>
                                            : <>
                                                <span className=
                                                    {
                                                        inputObject.salary!.length > 15 ? "share-img-salary-small" : "share-img-salary"
                                                    }
                                                >
                                                    {
                                                        inputObject.salary!.length < 15 && <span className="share-img-salary-title">เงินเดือน</span>}

                                                    {
                                                        !isNaN(Number(inputObject.salary)) ? Number(inputObject.salary).toLocaleString()
                                                            + " บาท / เดือน" : inputObject.salary + " บาท / เดือน"}  </span>
                                            </>
                                    }

                                    <div className="share-img-location-control">
                                        <img src={SHARE_PIN} className="share-img-location-pin"></img>
                                        <span className=
                                            {inputObject.location.length > 15 ? "share-img-location-pin-text-small" : "share-img-location-pin-text"}
                                        >{inputObject.location}</span>
                                    </div>
                                    <div className="share-img-qr-control">
                                        <div className="share-img-qr">
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={inputObject.url_job}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <span className="share-img-apply">สมัคร</span>
                                    </div>
                                    <div className="share-img-job-post-img-control">
                                        <img src={SHARE_JOB_POST} className="share-img-job-post-img"></img>
                                        <span className="share-img-job-post-img-text">โพสต์งานฟรีที่ {config.base_url}</span>
                                    </div>

                                </div>
                            }



                        </div>
                        <div className="d-flex">
                            <span className="share-img-back" onClick={handleClose}>ปิด</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ShareJobComponent;