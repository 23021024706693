
import "./talent-search-preview.scss";
import { useEffect, useRef, useState } from "react";

import config from "../../../config";
import HeaderLandingComponent from "../../landing/header-landing/header-landing";
import TalentSauceHeaderComponent from "../../landing/talent-sauce/header/talent-sauce-header";

const TalentSearchPreviewComponent = () => {
    const [urlIframe, setUrlIframe] = useState("");
    const iframeRef = useRef(null);

    useEffect(() => {

        const params = new URLSearchParams()
        params.append('isHeader', 'false');
        params.append('client_id', config.app_name);
        params.append('timeStamp', (new Date().toISOString()));
        const url = config.app.talent_search + "?" + new URLSearchParams(params)
        console.log(url);
        setUrlIframe(url);

    }, []);




    return (

        <div className="talent-search-preview-control">

            <div style={{ backgroundColor: '#000' }}>
                <TalentSauceHeaderComponent></TalentSauceHeaderComponent>
            </div>

            {
                urlIframe && <iframe
                    src={urlIframe}
                    width="100%"
                    height="100%"
                    allow="clipboard-write"
                    ref={iframeRef}
                ></iframe>
            }


        </div>
    )
}

export default TalentSearchPreviewComponent;